import { t } from '@lingui/macro';
import { i18nMark } from '@lingui/react';
import { ActionType, getType } from 'deox';
import get from 'lodash/get';
import { all, call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'; // debounce, fork, delay

import API from 'api';
import {
  parseApiObject,
  parseApiObjectV2,
  serializeDataV2,
  serializeParams,
} from 'components/utils/apiDataConverter';
import { getIsAuthenticated, getUser, iamActions } from 'ducks/authIAM';
import { notifyActions } from 'ducks/message';
import {
  bbpCurrentCartParamsDefaultState,
  bricsCurrentCartParamsDefaultState,
  CartImage,
  clearCurrentCartRequest,
  createCartRequest,
  CurrentCart, // todo -
  CurrentCartParams,
  currentCartParamsDefaultState,
  fetchCartOrder,
  fetchCartRequests,
  fetchCurrentCart,
  fetchImagesQuotation,
  fetchUserOrderTags,
  findCartRequests,
  getCurrentCartImages,
  getCurrentCartParams,
  getImageSourceName,
  setImageSource,
  updateCurrentCartImages,
  updateCurrentCartParams,
} from 'ducks/order'; // pickSimpleRouteCart
import { requestFlowNew } from './network';
import authRefresh from './networkIAM';

import { getPlatformInstrumentProcessingLevel } from 'components/map/UserMenu/SimpleCart/OutputProductParametrs/OutputProductParameters';
import { ImageSource } from 'ducks/types/orderTypes';
import { captureSagaException } from 'modules/monitor/sentryHelper';
import { stacPlatformTypes } from 'pages/HomePage/sections/SearchResult/OrderImage/utils';
import { Debugger } from 'utils/logging';
const debug = Debugger('OrderSaga'); // TAG

// helper func
const isEtris = (id: string) => id.startsWith('ETRIS.');
const isBbp = (id: string) => id.startsWith('BBP.');
const isStac = (id: string) => id.startsWith('APOI.');
const getImageSourceFromIdentifier = (id: string): ImageSource =>
  !isStac(id) ? (isEtris(id) ? 'etris' : isBbp(id) ? 'bbp' : null) : 'stac';

export const productCombination = {
  l1LatLongByte2: ['GF6.PMS.L1', 'GF6.WFV.L1', 'ZY302.MUX.L1'],
  l2UTMByte1: ['CB04.MUX.L2', 'CB04.PANMUX.L2', 'CB04.IRS.L2'],
  l2UTMByte2: [
    'AI2D.AVR.L2',
    'CB04.WFI.L2',
    'IRSR2A.LISS3.L2',
    'IRSR2A.LISS4.L2',
    'ZY3.BWD.L2',
    'SZ2M02.MUL12U-R.L2',
    'SZ2M04.MUL12U-R.L2',
    'SZ2M06.MUL12U-R.L2',
    'MM22.MSUMR.L2',
    'MM23.MSUMR.L2',
    'MM24.MSUMR.L2',
    'LS8.OLITIRS.L2',
    'SNL2A.MSI.L2',
    'MM22.MSUTM101.L2',
    'MM22.MSUTM102.L2',
    'MM23.MSUTM101.L2',
    'MM23.MSUTM102.L2',
    'MM24.MSUTM101.L2',
    'MM24.MSUTM102.L2',
    'MM2-3.MSU101.L1',
    'MM2-3.MSU102.L1',
    'MM2-4.MSU101.L1',
    'MM2-4.MSU102.L1',
  ],
  l2LatLongByte2: ['GF6.PMS.L2', 'GF6.WFV.L2', 'IRSR2A.AWiFS.L2'],
};

const getKeyByValue = (object: any, value: any): ImageSource => {
  return Object.keys(object).find(key => object[key].includes(value))! as ImageSource;
};

const getImageSource = (id: string) => {
  let imageSourceFromID = getImageSourceFromIdentifier(id);
  if (imageSourceFromID === 'bbp' || imageSourceFromID === 'stac') {
    return imageSourceFromID;
  } else {
    imageSourceFromID = getKeyByValue(
      productCombination,
      getPlatformInstrumentProcessingLevel(id)
    )!;
    if (imageSourceFromID) {
      return imageSourceFromID;
    } else {
      return 'etris';
    }
  }
};

const imageSourceCartParams: { [key: string]: any } = {
  etris: currentCartParamsDefaultState,
  bbp: bbpCurrentCartParamsDefaultState,
  l1Only: bricsCurrentCartParamsDefaultState,
};

// export function* addImageToCurrentCartWatch() {
//   yield takeLatest(getType(pickSimpleRouteCart.add), addImageToCurrentCartFlow); // что это даёт getType? - работает и без него
// }
export function* updateCurrentCartImagesWatch() {
  yield takeEvery(updateCurrentCartImages.request, updateCurrentCartImagesFlow);
}
export function* updateCurrentCartImagesFlow(
  action: ActionType<typeof updateCurrentCartImages.request>
) {
  const {
    payload, // payload: image, imageMd,
    meta: { effect },
  } = action;
  // const imageMds = Array.isArray(payload) ? payload : [payload];
  // const cartImages: CartImage[] = imageMds.map(md => ({
  //   metadataIdentifier: md.identifier,
  //   croppingPolygon: md.geometry,
  //   price: 0,
  // }));
  const cartImages = Array.isArray(payload) ? payload : [payload];

  // const image: CartImage = {
  //   metadataIdentifier: imageMd.identifier,
  //   croppingPolygon: imageMd.geometry,
  //   price: 0, // TODO - убрать
  // };
  let imageIndex = -1;
  let currentImage = undefined as CartImage | undefined; // let currentImage: CartImage;
  const needWarnCropping: boolean[] = [];
  try {
    const user: ReturnType<typeof getUser> = yield select(getUser);
    // .slice() // новый массив, чтобы не портить при reverse()
    // .reverse() // ищем с конца для простановки номера
    const imagesAdded: CartImage[] = (yield select(getCurrentCartImages)).slice().reverse();

    const currentImageSourceName: ImageSource = yield select(getImageSourceName);
    let imageSourceName: ImageSource = null;
    if (effect === 'add' && imagesAdded.length === 0) {
      imageSourceName = getImageSource(cartImages[0].metadataIdentifier);
      if (currentImageSourceName !== imageSourceName) {
        yield put(setImageSource(imageSourceName));
        yield put(updateCurrentCartParams.request(imageSourceCartParams[imageSourceName]));
        yield take(updateCurrentCartParams.success);
      }
    } else {
      imageSourceName = currentImageSourceName;
    }
    for (const image of cartImages) {
      if (effect === 'add') {
        needWarnCropping.push(
          stacPlatformTypes.filter(platform =>
            image.metadataIdentifier.split('.')[1].startsWith(platform)
          ).length > 0 && image.croppingPolygon !== undefined
        );
      }
    }
    if (needWarnCropping.some(item => item)) {
      yield put(
        notifyActions.push({
          message: t(
            'messages.cart_metadata_warn'
          )`После обрезки изображения продукт сопровождается метаданными полной сцены`,
          place: 'bc',
        })
      );
    }
    for (const image of cartImages) {
      currentImage = image;
      // imageIndex = images.indexOf(image); // работает только для действия remove!
      imageIndex = imagesAdded.findIndex(
        // item => item.metadataIdentifier === image.metadataIdentifier
        item => item.metadataIdentifier.startsWith(image.metadataIdentifier)
      );

      // const imageSerialized = serializeParams(image);

      if (effect === 'add') {
        // validate image source compatibility
        const platformInstrument = getImageSource(currentImage.metadataIdentifier);
        if (imageSourceName !== platformInstrument) {
          yield put(
            notifyActions.push({
              color: 'alert',
              message: t(
                'messages.add_image_comb_validation_failed'
              )`Нельзя комбинировать снимки с разными параметрами выдачи продукции`,
              place: 'bc',
            })
          );
          return;
        }

        let n = 0;
        // повторное добавление (для фрагментов в первую очередь)
        if (imageIndex > -1) {
          // continue; // return;
          // const duplicateIndex = imagesAdded.findIndex(
          //   item =>
          //     item.bandCombination === image.bandCombination &&
          //     item.croppingPolygon === image.croppingPolygon &&
          //     JSON.stringify(item.bands) === JSON.stringify(image.bands)
          // );
          // if (duplicateIndex > -1) {
          //   yield put(
          //     notifyActions.push({
          //       color: 'alert',
          //       message: t(
          //         'messages.add_image_duplicate_exist'
          //       )`Снимок с аналогичными параметрами заказа уже добавлен в корзину`,
          //       place: 'bc',
          //     })
          //   );
          //   return;
          // }
          n = Number(imagesAdded[imageIndex].metadataIdentifier.split(':')[1] || n) + 1;
        }
        image.metadataIdentifier =
          n > 0 ? `${image.metadataIdentifier}:${n}` : image.metadataIdentifier;
        // yield put(updateCurrentCartImages.add(image));

        const imageSerialized = serializeParams(image);
        const respData = yield call(
          authRefresh,
          requestFlowNew,
          API.addImageToCurrentCart,
          imageSerialized
        );
        debug.log('Add image response data', respData);
        const tempAddedImageWithPrice = respData.images.find(
          (item: any) => item.metadata_identifier === image.metadataIdentifier
        );
        Object.assign(image, tempAddedImageWithPrice); // add area, price
        // TODO - free pay?
        image.price = user.haveToPay() ? tempAddedImageWithPrice.price : 0;
        yield put(updateCurrentCartImages.add(image));

        // TODO - показать уведомление: Снимок {} успешно добавлен в корзину
      } else if (effect === 'remove') {
        yield put(updateCurrentCartImages.remove(image));

        const imageSerialized = serializeParams(image);
        const respData = yield call(
          authRefresh,
          requestFlowNew,
          API.removeImageFromCurrentCart,
          imageSerialized
        );
        debug.log('Remove image response data', respData);
      }
    }
    yield put(
      notifyActions.push({
        message: t('messages.cart_update_successed')`Корзина успешно изменена`,
        place: 'bc',
      })
    );
  } catch (error) {
    debug.error(error);
    if (effect === 'add') {
      // yield put(updateCurrentCartImages.remove(image));
    } else if (effect === 'remove' && currentImage !== undefined) {
      yield put(updateCurrentCartImages.add(currentImage, imageIndex));
    }
    // yield put(pickSimpleRouteCart.remove(imageMd));

    // TODO - не добавлять снимок в корзину при ошибке!!!
    // но это не так критично должно быть, т.к. при поиске словим ошибку на невалидный район интереса
    let errorMessage: string = '';
    if (error.isAxiosError) {
      if (
        error.response.data.hasOwnProperty('images') &&
        Array.isArray(error.response.data.images)
      ) {
        errorMessage = `. ${Object.values(error.response.data.images[0]).join(',')}`;
      }
    }

    // TODO - показать уведомление: Ошибка при добавлении снимка {} в корзину
    yield put(
      notifyActions.push({
        color: 'alert',
        message: t(
          'messages.cart_update_failed'
        )`Ошибка при попытке изменить корзину${errorMessage}`,
        place: 'bc',
      })
    );

    captureSagaException(error as Error, action.type, action.payload);
  }
}

export function* updateCurrentCartParamsWatch() {
  yield takeLatest(getType(updateCurrentCartParams.request), updateCurrentCartParamsFlow);
}
export function* updateCurrentCartParamsFlow(
  action: ActionType<typeof updateCurrentCartParams.request>
) {
  try {
    const user: ReturnType<typeof getUser> = yield select(getUser);
    const params = action.payload;
    const paramsSerialized = serializeDataV2(params); // serializeParams

    const respData = yield call(
      authRefresh,
      requestFlowNew,
      API.patchCurrentCart,
      paramsSerialized
    );
    debug.log('Patch cart params response data', respData);
    const parsedData = parseApiObject(respData);

    // TODO - pay free?
    if (!user.haveToPay()) {
      parsedData.totalPrice = 0;
      parsedData.images.forEach((img: any) => (img.price = 0));
    }

    yield put(updateCurrentCartParams.success(parsedData));
  } catch (error) {
    debug.error(error);
    let errorMessage: string = '';
    if (error.isAxiosError) {
      errorMessage = Object.values(error.response.data).join(',');
    }
    yield put(
      notifyActions.push({
        color: 'alert',
        message: `${errorMessage}`,
        place: 'bc',
      })
    );
    // TODO - показать уведомление: Ошибка при изменении параметров корзины
    yield put(updateCurrentCartParams.failure(error));
    captureSagaException(error as Error, action.type, action.payload);
  }
}

export function* clearCurrentCartWatch() {
  yield takeLatest(getType(clearCurrentCartRequest), clearCurrentCartFlow);
}
export function* clearCurrentCartFlow(action: ActionType<typeof clearCurrentCartRequest>) {
  try {
    const params: CurrentCart = {
      ...currentCartParamsDefaultState,
      images: [],
      totalPrice: 0,
    };
    const serializedParams = serializeDataV2(params);
    const respData = yield call(
      authRefresh,
      requestFlowNew,
      API.patchCurrentCart,
      serializedParams
    );
    debug.log('Patch cart params response data', respData);

    const parsedData = parseApiObject(respData);
    // yield put(updateCurrentCartParams.success(respData));
    yield put(fetchCurrentCart.success(parsedData));
    yield put(setImageSource(null));
  } catch (error) {
    debug.error(error);
    // TODO - показать уведомление: Ошибка при изменении параметров корзины
    // yield put(updateCurrentCartParams.failure(error));
    captureSagaException(error, action.type);
  }
}

export function* fetchCurrentCartWatch() {
  yield takeLatest(getType(fetchCurrentCart.request), fetchCurrentCartFlow);
}
export function* fetchCurrentCartFlow(_action: ActionType<typeof fetchCurrentCart.request>) {
  try {
    const user: ReturnType<typeof getUser> = yield select(getUser);

    const respData = yield call(authRefresh, requestFlowNew, API.getCurrentCart, {});
    debug.log('Get current cart response data', respData);
    const parsedData = parseApiObject(respData);

    // TODO - pay free?
    if (!user.haveToPay()) {
      parsedData.totalPrice = 0;
      parsedData.images.forEach((img: any) => (img.price = 0));
    }

    if (parsedData.images.length > 0) {
      const imageSourceName = getImageSource(parsedData.images[0].metadataIdentifier);
      yield put(setImageSource(imageSourceName));
    }

    yield put(fetchCurrentCart.success(parsedData));
  } catch (error) {
    debug.error(error);
    yield put(fetchCurrentCart.failure(error));
    captureSagaException(error, _action.type);
  }
}

export function* fetchCartRequestsWatch() {
  yield takeLatest(getType(fetchCartRequests.request), fetchCartRequestsFlow);
}
export function* fetchCartRequestsFlow(action: ActionType<typeof fetchCartRequests.request>) {
  try {
    const user: ReturnType<typeof getUser> = yield select(getUser);
    const params = action.payload || {};
    // TODO - вынести в отдельную saga декоратор
    const isAuthenticated: ReturnType<typeof getIsAuthenticated> = yield select(getIsAuthenticated);
    if (!isAuthenticated) {
      yield take(iamActions.success);
    }
    const respData = yield call(authRefresh, requestFlowNew, API.getCartRequests, params);
    debug.log('Get cart requests response data', respData);
    const parsedData = parseApiObject(respData);

    // TODO - pay free?
    if (!user.haveToPay()) {
      parsedData.results.forEach((result: any) => (result.totalPrice = 0));
    }

    yield put(fetchCartRequests.success(parsedData));
  } catch (error) {
    debug.error(error);
    yield put(fetchCartRequests.failure(error));
    captureSagaException(error, action.type, action.payload);
  }
}

// TODO - temp (объединить с fetchCartRequestsWatch)
export function* findCartRequestsWatch() {
  yield takeLatest(getType(findCartRequests.request), findCartRequestsFlow);
}
export function* findCartRequestsFlow(action: ActionType<typeof findCartRequests.request>) {
  try {
    const { callback, ...params } = action.payload || {};
    const paramsSerialized = serializeParams(params);
    // TODO - вынести в отдельную saga декоратор
    const isAuthenticated: ReturnType<typeof getIsAuthenticated> = yield select(getIsAuthenticated);
    if (!isAuthenticated) {
      yield take(iamActions.success);
    }
    const respData = yield call(authRefresh, requestFlowNew, API.getCartRequests, paramsSerialized);
    debug.log('Find cart requests response data', respData);
    const parsedData = parseApiObject(respData);
    // yield put(findCartRequests.success(parsedData));
    if (callback !== undefined) {
      callback(parsedData); // <-- pass value (orders)
    }
  } catch (error) {
    debug.error(error);
    // yield put(findCartRequests.failure(error));
    captureSagaException(error as Error, action.type, action.payload);
  }
}

export function* fetchCartOrderWatch() {
  yield takeLatest(getType(fetchCartOrder.request), fetchCartOrderFlow);
}
export function* fetchCartOrderFlow(action: ActionType<typeof fetchCartOrder.request>) {
  try {
    // const orderNumber = action.payload;
    const { orderNumber, params, callback } = action.payload;

    const respData = yield call(authRefresh, requestFlowNew, API.getCartOrder, orderNumber, params);
    debug.log('Get cart order response data', respData);
    const parsedData = parseApiObjectV2(respData, { removeDate: 'date', createdAt: 'date' }); // parseApiObject(respData);
    yield put(fetchCartOrder.success(parsedData));

    if (callback !== undefined) {
      callback(parsedData);
    }
  } catch (error) {
    debug.error(error);
    yield put(fetchCartOrder.failure(error));
    captureSagaException(error as Error, action.type, action.payload);
  }
}

export function* fetchImagesQuotationWatch() {
  yield takeLatest(getType(fetchImagesQuotation.request), fetchImagesQuotationFlow);
}
export function* fetchImagesQuotationFlow(action: ActionType<typeof fetchImagesQuotation.request>) {
  const { callback, ...params } = action.payload || {};
  try {
    const user: ReturnType<typeof getUser> = yield select(getUser);
    const cartParams: CurrentCartParams = yield select(getCurrentCartParams);

    // invalid geom. To test order api errors
    // params.croppingPolygon = 'POLYGON((79.33592585456171 66.0135470564152,96.56248835456171 64.61916289272656,86.3671758545617 60.66779069753349,91.1132696045617 67.5043602101654,79.33592585456171 66.0135470564152))';
    let parsedData;
    // TODO - pay free?
    if (!user.haveToPay()) {
      parsedData = { totalPrice: 0 };
    } else {
      const paramsSerialized = serializeDataV2({
        ...params,
        productCode: cartParams.productCode,
        elevation: cartParams.elevation,
        certification: cartParams.certification,
        license: cartParams.license,
        usageTime: cartParams.usageTime,
      }); // serializeParams
      // TODO - вынести в отдельную saga декоратор
      // const isAuthenticated = yield select(getIsAuthenticated);
      // if (!isAuthenticated) {
      //   yield take(iamActions.success);
      // }
      const respData = yield call(authRefresh, requestFlowNew, API.getPrice, paramsSerialized);
      debug.log('Get quotation for image(s) response data', respData);
      parsedData = parseApiObject(respData);
    }
    // yield put(fetchImagesQuotation.success(parsedData));
    if (callback !== undefined) {
      callback(parsedData); // <-- pass value (orders)
    }
  } catch (error) {
    debug.error(error);

    // if (get(error, 'response.status') === 400 && get(error, 'response.data')) {
    if (error.isAxiosError) {
      // TODO - читать ошибку api и выводить её с переводом (по коду ошибки)
      const errorMessage: string = error.response.data.images
        .map((image: object) => Object.values(image))
        .join(', ');

      yield put(
        notifyActions.push({
          color: 'alert',
          message: errorMessage,
          place: 'bc',
        })
      );
      if (callback !== undefined) {
        callback(new Error('InvalidGeometry')); // <-- pass Error
      }
    }
    captureSagaException(error as Error, action.type, action.payload);
  }
}

export function* createCartRequestWatch() {
  yield takeLatest(getType(createCartRequest.request), createCartRequestFlow);
}
export function* createCartRequestFlow(_action: ActionType<typeof createCartRequest.request>) {
  try {
    const respData = yield call(authRefresh, requestFlowNew, API.createCartRequest, {});
    /*
      payment_params: {​​
        form_url: "https://3dsec.sberbank.ru/payment/merchants/sbersafe/payment_ru.html?mdOrder=e49a52bc-48b0-71eb-896d-74505e27f576",
        order_id: "e49a52bc-48b0-71eb-896d-74505e27f576"
      }
    */
    debug.log('Create cart requests response data', respData);
    yield put(createCartRequest.success());
    yield put(
      notifyActions.push({
        message: i18nMark('Заявка успешно создана'), // TODO - ...перенаправляем на страницу оплаты
        place: 'bc',
      })
    );
    yield put(setImageSource(null));
    // перенаправление на страницу оплаты
    yield delay(2000);
    const paymentFormUrl = get(respData, 'payment_params.form_url');
    const orderId = get(respData, 'payment_params.order_id');
    if (paymentFormUrl) {
      window.location.replace(paymentFormUrl);
    } else if (orderId === 'free') {
      // pass
      // Бесплатные халявщики-заказчики
    } else {
      yield put(
        notifyActions.push({
          // color: 'warning',
          message: t(
            'messages.cart_order_create_warn'
          )`Ваш заказ успешно оформлен. Но произошла ошибка при открытии формы оплаты. Выполнить оплату повторно можно из Личного кабинета`,
          place: 'bc',
        })
      );
    }
  } catch (error) {
    let errorMessage: string = '';
    if (error.isAxiosError) {
      errorMessage = `. ${Object.values(error.response.data).join(',')}`;
    } else {
      yield put(createCartRequest.failure(error));
    }
    yield put(
      notifyActions.push({
        color: 'alert',
        message: t('messages.cart_order_create_error')`Ошибка при создании заявки${errorMessage}`,
        place: 'bc',
      })
    );
    captureSagaException(error as Error, _action.type);
  }
}

export function* fetchUserOrderTagsWatch() {
  yield takeLatest(getType(fetchUserOrderTags.request), fetchUserOrderTagsFlow);
}
export function* fetchUserOrderTagsFlow(action: ActionType<typeof fetchUserOrderTags.request>) {
  try {
    // TODO - вынести в отдельную saga декоратор
    const isAuthenticated: ReturnType<typeof getIsAuthenticated> = yield select(getIsAuthenticated);
    if (!isAuthenticated) {
      yield take(iamActions.success);
    }
    const respData = yield call(authRefresh, requestFlowNew, API.getUserOrderTags);
    debug.log('Get user tags response data', respData);
    const parsedData = respData; // parseApiObject(respData);

    yield put(fetchUserOrderTags.success(parsedData));
  } catch (error) {
    debug.error(error);
    yield put(fetchUserOrderTags.failure(error));
    captureSagaException(error as Error, action.type, {});
  }
}

export default function* orderRoot() {
  yield all([
    // addImageToCurrentCartWatch(),
    updateCurrentCartImagesWatch(),
    updateCurrentCartParamsWatch(),
    clearCurrentCartWatch(),
    fetchCurrentCartWatch(),
    fetchCartRequestsWatch(),
    findCartRequestsWatch(), // todo -
    fetchCartOrderWatch(),
    fetchImagesQuotationWatch(),
    createCartRequestWatch(),
    fetchUserOrderTagsWatch(),
  ]);
}

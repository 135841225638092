import Checkbox from '@geobank/components/src/forms/CheckBox/Checkbox';
import { Trans } from '@lingui/macro';
// import { withI18n, withI18nProps } from '@lingui/react';
import React from 'react';

interface CoverageExistsParamProps {
  coverageExists: boolean;
  onChange: (field: string, value: any) => void;
}

const CoverageExistsParam: React.FC<CoverageExistsParamProps> = props => {
  const { coverageExists, onChange } = props;

  const handleChange = (name: string, value: boolean) => {
    onChange(name, value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '-10px',
      }}
    >
      <Checkbox
        name="coverageExists"
        // label={i18n._(t`Только снимки с просмотром в полном разрешении`)}
        label={''}
        colorLabel="#FFFFFF"
        onChange={handleChange}
        checked={coverageExists}
      />
      <div style={{ color: '#FFFFFF', fontSize: '17px', marginTop: '10px' }}>
        <Trans>С возможностью просмотра в полном разрешении без оформления заказа</Trans>
      </div>
    </div>
  );
};

export default CoverageExistsParam;

import {
  canOrderImageByInstrument,
  // canOrderOperativeImageAndGetDiffByDate,
} from 'components/utils/order';
// import TileLayer from 'libs/ol/layer/Tile';
import {
  blockedStyle,
  boldStyle,
  disabledStyle,
  emptyStyle,
  geofenceStyle,
  getCustomStyle,
  intersectionStyle,
  selectStyle,
  thinStyle,
} from './featureStyles';

// import { apoiRouteIdentifier } from 'components/utils/format';
import { ImageMetadata } from 'ducks/metadata';
import { BandCombinationType, ImageMetadataDetail } from 'ducks/types/metadataTypes';
import { LayerGroup } from 'libs/ol/layer/Group';
import VectorLayer from 'libs/ol/layer/Vector';
// import { Select } from 'ol/interaction';
import { Collection, Feature } from 'ol';
import { Attribution, ScaleLine, Zoom } from 'ol/control';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
// import { Modify, Snap } from 'ol/interaction';
import WKT from 'ol/format/WKT';
import Geometry from 'ol/geom/Geometry';
import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import ImageLayer from 'ol/layer/Image';
import BrightnessTemperatureControl from './CustomControls/BrightnessTemperatureControl/BrightnessTemperatureControl';

import TileLayer from 'libs/ol/layer/Tile';
import OLMap from 'ol/Map';
import { Vector as VectorSource } from 'ol/source';
import ImageSource from 'ol/source/Image';
import Static from 'ol/source/ImageStatic';
import Style from 'ol/style/Style';
import OLView from 'ol/View';
import { Debugger } from 'utils/logging';
import { getCanvasPatternV1 } from './canvas';
import CoordinateControl, {
  hdmsCoordinateFormat,
} from './CustomControls/CoordinateControl/CoordinateControl';
// import ZoomLevelControl from './CustomControls/ZoomLevelControl/ZoomLevelControl';
import { getLayersDataFromImageMetadata } from './helpers/layer';
import { createSimpleVectorLayer } from './layers'; // createSimpleXYZLayer
import { CreateLayerBundle } from './types';
import { createLayer } from './utils';

const debug = Debugger('Map'); // TAG

export type GeometryFormat = 'WKT' | 'GeoJSON';

// LayerGroups, LAYER_GROUP
export enum LAYER_GROUPS {
  baseLayers = 'BASE_LAYERS',
  imageLayers = 'IMAGE_LAYERS',
  userLayers = 'USER_LAYERS',
  topLayers = 'TOP_LAYERS',
  mosaicLayers = 'MOSAIC_LAYERS',
}

export const mosaicLayerIds = [
  'MSUGS',
  'MSUGS_BT',
  'MSUGS_BT9',
  'MSUMR_D',
  'MSUMR_N',
  'MSUMR',
  'MSUGS_A_EL',
];

export const LANGUAGE_CODE: { [key: string]: string } = {
  ru: 'ru_RU',
  en: 'en_RU',
};

// main layer groups (default)
const defaultLayers = [
  new LayerGroup({
    name: LAYER_GROUPS.baseLayers,
    zIndex: 10,
  }),
  new LayerGroup({
    name: LAYER_GROUPS.imageLayers,
    zIndex: 20,
  }),
  new LayerGroup({
    name: LAYER_GROUPS.userLayers,
    zIndex: 30,
  }),
  new LayerGroup({
    name: LAYER_GROUPS.topLayers,
    zIndex: 40,
  }),
  new LayerGroup({
    name: LAYER_GROUPS.mosaicLayers,
    zIndex: 30,
  }),
];

class GeoportalMap {
  private static _instance?: GeoportalMap;

  public _currentBaseLayerId?: string = 'Yandex';

  private _olMap: OLMap;

  private _currentSelectedMetadataFeature?: Feature;
  private _currentSelectedMetadataFeatureStyle?: Style;

  constructor(...params: any) {
    if (GeoportalMap._instance) {
      throw new Error(
        'Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.'
      );
    }

    const layers = params.layers || defaultLayers;
    const scaleLineControl = new ScaleLine();
    // const zoomSlider = new ZoomSlider();
    const zoomControl = new Zoom();
    // https://openlayers.org/en/latest/apidoc/module-ol_control_Attribution-Attribution.html
    const attributionControl = new Attribution({
      collapsed: false,
      collapsible: true,
      // label: 'i »',
      collapseLabel: '«',
    });

    // const zoomLevelControl = new ZoomLevelControl({});
    const customCoordinateControl = new CoordinateControl({
      coordinateFormat: hdmsCoordinateFormat,
      projection: 'EPSG:4326',
    });
    const controls = [
      scaleLineControl,
      zoomControl,
      attributionControl,
      // mousePositionControl,
      customCoordinateControl,
      // zoomLevelControl,
    ]; // defaultControls().extend([scaleLineControl]);

    // TODO - measure
    // https://openlayers.org/en/latest/examples/measure.html?q=coordinates

    // openlayers map
    this._olMap = new OLMap({
      // wrapX: false,
      layers,
      // layers: [
      //   new TileLayer({
      //     id: 11,
      //     name: 'osm',
      //     source: new OSM(),
      //   }),
      // ],
      controls,
      // overlays: [pointCoordinateOverlay],
      view: new OLView({
        projection: 'EPSG:3857',
        // center: [0, 0],
        center: [8000000, 9000000],
        zoom: 4,
        minZoom: 2, // 0
        maxZoom: 20, // 28
        constrainResolution: true,
      }),
    });
    // instance = this;
    // return instance;
    GeoportalMap._instance = this;
  }

  public static getInstance() {
    if (GeoportalMap._instance) {
      return GeoportalMap._instance;
    } else {
      throw new Error('Singleton instance not created');
    }
  }

  public getOlMap() {
    return this._olMap;
  }

  public setView(view: OLView) {
    this._olMap.setView(view);
  }

  private _findLayerGroup(name: LAYER_GROUPS): LayerGroup {
    const group = this.getOlMap()
      .getLayers()
      .getArray()
      .find(layer => layer.get('name') === name && layer instanceof LayerGroup);
    return group as LayerGroup;
  }

  // _findLayerBy
  private _findChildLayerBy(parent: LayerGroup, filterFunc: (item: any) => boolean) {
    // const layer = this._findLayerGroup(groupName)
    const layer = parent.getLayers().getArray().find(filterFunc);
    return layer;
  }

  // _parseLayer, _prepareLayer
  private _parseLayer(layer: any): BaseLayer {
    if (layer instanceof Layer) {
      return layer;
    } else {
      if (!layer.type) {
        throw new Error('Layer type is empty');
      }
      return createLayer(layer.type, layer);
    }
  }

  /* public addLayer(layer: BaseLayer) {
    this._olMap.addLayer(layer);
  } */

  public setBaseLayers(layers: any[]) {
    const group = this._findLayerGroup(LAYER_GROUPS.baseLayers); // было imageLayers ?!
    // group.addLayer(this._parseLayer(layer));
    const olLayers: BaseLayer[] = [];
    layers.forEach(item => {
      if (item.config.options.visible || item.config.options.visibility) {
        this._currentBaseLayerId = item.id;
      }
      olLayers.push(this._parseLayer(item));
    });
    // this.getLayerGroup().getLayers()
    group.getLayers().extend(olLayers);
  }

  // selectBaseLayer(oldValue, newValue) {
  public toggleBaseLayer(value: string) {
    const oldValue = this._currentBaseLayerId;
    const newValue = value;

    const group = this._findLayerGroup(LAYER_GROUPS.baseLayers);
    const layers = group.getLayers().getArray();
    const currLayer = layers.find(elem => elem.get('id') === oldValue);
    const newLayer = layers.find(elem => elem.get('id') === newValue);

    newLayer!.set('visible', true);
    currLayer!.set('visible', false);

    this._currentBaseLayerId = value;
  }

  public updateYandexLayerUrl(layerData: any, language: string, layerGroup: LAYER_GROUPS) {
    const layer = this.getLayerById(layerData.id, layerGroup) as TileLayer;
    if (layer) {
      const previousUrl = new URL(layerData.config.options.url);
      previousUrl.searchParams.set('lang', LANGUAGE_CODE[language]);
      const source = layer.getSource();
      // @ts-ignore
      source!.setUrl(decodeURI(previousUrl.href));
      debug.log('updateYandexLayer', layer);
    }
  }

  public readGeometry(
    geometry: any,
    geometryType: GeometryFormat,
    projected: boolean = true
  ): Feature<Geometry> {
    const format = geometryType === 'WKT' ? new WKT() : new GeoJSON();
    const feature = format.readFeature(
      geometry,
      projected ? { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' } : {}
    );
    return feature;
  }

  public writeGeometry(geometry: any, geometryType: GeometryFormat, projected: boolean = true) {
    const format = geometryType === 'WKT' ? new WKT() : new GeoJSON();
    const feature = format.writeFeature(
      geometry,
      projected ? { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' } : {}
    );
    return feature;
  }

  // TODO - завернуть в утилиту?, назвать parseVectorFeature()

  public _parseFeature(value: ImageMetadata) {
    if (!value.geometry) {
      return null;
    }
    // const canOrderOperativeImage = canOrderOperativeImageAndGetDiffByDate(
    //   value.acquisitionDateBegin || value.acquisitionDateInstant
    // )[0];
    const canOrderImage = canOrderImageByInstrument(
      value.instrumentIdentifier,
      value.platformIdentifier
    );
    const feature = this.readGeometry(value.geometry, 'WKT');
    canOrderImage // && canOrderOperativeImage
      ? feature.setStyle(thinStyle)
      : feature.setStyle(blockedStyle);
    feature.setProperties({ identifier: value.identifier });
    feature.setId(value.identifier);
    feature.set('_type', 'metadata'); // temp!!!
    return feature;
  }

  public addGeofenceLayer(geofenceGeojson: any, isZoomNeed = false) {
    const feature = this.readGeometry(geofenceGeojson, 'GeoJSON');
    feature.setStyle(geofenceStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = this.ensureCurrentRegionLayer('geofence');
    layer.setSource(vectorSource);

    const geofence = new GeoJSON();
    const geoJSON = geofence.writeFeatureObject(feature);

    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    }
    return geoJSON;
  }

  public changeGeofencelayerStyle(style: Style) {
    const layer = this.getLayerById('geofence', LAYER_GROUPS.userLayers) as VectorLayer;
    layer.setStyle(style);
  }

  public addIntersectionLayer(intersectionGeojson: any, isZoomNeed = false) {
    const feature = new GeoJSON().readFeature(intersectionGeojson);
    feature.setStyle(intersectionStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = this.ensureCurrentRegionLayer('intersection');
    layer.setSource(vectorSource);

    const geofence = new GeoJSON();
    const geoJSON = geofence.writeFeatureObject(feature);

    // if (feature && isZoomNeed) {
    //   this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    // }
    return geoJSON;
  }

  public centerMapViewOnSearchObject(object: any) {
    const feature = this.readGeometry(object.geometry, 'GeoJSON');
    this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
  }

  public addRegionOfInterestLayer(object: any, isZoomNeed = false) {
    const feature = this.readGeometry(object.geometry, 'GeoJSON');
    feature.setId(object.id);
    feature.set('_type', object.type);
    feature.setStyle(object.geometry.coordinates.length === 1 ? selectStyle : disabledStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = this.getLayerById('regionLayer', LAYER_GROUPS.userLayers) as VectorLayer;
    layer.setSource(vectorSource);
    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    }
  }

  public selectObject(object: any, isZoomNeed = false) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);

    // public ensureCurrentRegionLayer() {}
    let layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'objectsLayer'
    ) as VectorLayer;
    if (!layer) {
      const bundle: CreateLayerBundle = {
        id: 'objectsLayer',
        name: 'objectsLayer',
        options: { url: '', zIndex: 30 },
      };
      layer = createSimpleVectorLayer(bundle);
      this.addUserLayer(layer);
    }
    // return layer;

    let feature;
    // if (object.geometry.constructor.name === 'Object') {
    if (object.type === 'osm') {
      const format = new GeoJSON();
      feature = format.readFeature(object.geometry);
    } else {
      // feature = new Feature(object.geometry)
      feature = new Feature({
        geometry: object.geometry.clone(), // копируем геометрию, чтобы потом два раза не трансформировалась
        // geometry: new Polygon(polyCoords),
        // labelPoint: new Point(labelCoords),
        // name: 'My Polygon',
        _type: object.type, // gptl-images
        data: object.data,
      });
    }
    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    feature.setId(object.id);
    // feature.set('_type', 'region'); // пока отключено: добавление в закладки и выделение

    debug.log('selectObject', object, feature);
    layer.getSource()!.clear();
    layer.getSource()!.addFeature(feature);

    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry().getExtent(), { maxZoom: 10 });
    }
  }

  public clearObjects() {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);

    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'objectsLayer'
    ) as VectorLayer;

    if (layer) {
      layer.getSource()!.clear();
      // console.log('clear');
    }
  }

  public toggleImageContour(
    imageMdIds: string[],
    isImagesFullResActive: boolean[],
    flag: boolean | undefined = true
  ) {
    const idsLength = imageMdIds.length;
    for (let i = 0; i < idsLength; i++) {
      const metadataId = imageMdIds[i];
      const isViewFullImageActive = isImagesFullResActive[i];
      const isImageContourActive = !flag;

      this.toggleMetadataContour(metadataId, isViewFullImageActive, isImageContourActive);
    }
  }
  public toggleMetadataContour(
    metadataId: ImageMetadata['identifier'], // metadataId: number,
    isViewFullImageActive?: boolean,
    isImageContourActive?: boolean
  ) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;

    const feature = layer.getSource()!.getFeatureById(metadataId);

    const featureStyle = feature!.getStyle() as Style;
    let newStyle;
    if (
      (!featureStyle || featureStyle.getStroke()) &&
      (feature!.get('__hidden') === false || feature!.get('__hidden') === undefined)
    ) {
      // feature.set('__hidden', true);
      // feature.setStyle(emptyStyle); // hide
      newStyle = emptyStyle;
    } else {
      // feature.set('__hidden', false);
      // feature.setStyle(null); // show
      // feature.setStyle(
      //   this._currentSelectedMetadataFeature!.getId() === feature.getId() ? boldStyle : thinStyle
      // );
      if (isViewFullImageActive === true) {
        const fillColor = getCanvasPatternV1();
        newStyle = getCustomStyle({ fill: { color: fillColor } });
      } else if (this._currentSelectedMetadataFeature?.getId() === feature!.getId()) {
        newStyle = boldStyle;
      } else {
        newStyle = thinStyle;
      }
    }
    feature!.setStyle(newStyle);
    // feature.setStyle(new Style());

    feature!.set('__hidden', isImageContourActive); // !isImageContourActive
  }

  // previewsTemp,
  public selectMetadataFeature(metadataId: ImageMetadata['identifier'], isZoomNeed = true) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;
    if (this._currentSelectedMetadataFeature && this._currentSelectedMetadataFeatureStyle) {
      this._currentSelectedMetadataFeature.get('__hidden') === true
        ? this._currentSelectedMetadataFeature.setStyle(emptyStyle)
        : this._currentSelectedMetadataFeature.setStyle(this._currentSelectedMetadataFeatureStyle);
    }
    // feature может быть null, если не добавлена на карту из-за `poleAreaIntersection`
    const feature = layer.getSource()!.getFeatureById(metadataId);
    this._currentSelectedMetadataFeature = feature!;
    this._currentSelectedMetadataFeatureStyle = feature!.getStyle() as Style;
    feature && feature.setStyle(boldStyle);
    // this.mapInstance.getView().fit(feature.getGeometry().getExtent())
    if (feature && isZoomNeed) {
      this.getOlMap().getView().fit(feature.getGeometry()!.getExtent());
    }
    debug.log('selected feature', feature);
  }

  public clearMetadataFeatures(ids: Array<ImageMetadata['identifier']>) {
    ids.forEach(id => this.removeMetadataFeature(id));
  }

  public clearRegionsOfInterest() {
    const layer = this.getLayerById('regionLayer', LAYER_GROUPS.userLayers) as VectorLayer;
    layer.getSource()!.clear();
    // this.removeLayerByKey('regionLayer', 'name', LAYER_GROUPS.userLayers);
  }

  private clearRemovedLayers(layerGroup: LayerGroup, layerType: string, identifier: string) {
    const layers = layerGroup
      .getLayers()
      .getArray()
      .filter(_layer => _layer.get('name').startsWith(`${layerType}:${identifier}`));
    if (layers.length > 0) {
      for (const layer of layers) {
        layerGroup.getLayers().remove(layer);
      }
    }
  }

  public removeMetadataFeature(metadataId: ImageMetadata['identifier']) {
    const group: LayerGroup = this._findLayerGroup(LAYER_GROUPS.userLayers);
    const layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;

    const identifierToRemove = metadataId;
    // = metadataId.startsWith('APOI')
    // ? apoiRouteIdentifier(metadataId)
    // : metadataId;

    // const feature = layer.getSource()!.getFeatureById(metadataId);
    const features = layer
      .getSource()!
      .getFeatures()
      .filter(feature => feature.getId()?.toString().startsWith(identifierToRemove));
    for (const feature of features) {
      layer.getSource()!.removeFeature(feature);
    }

    // remove image previews
    this.clearRemovedLayers(group, 'previews', identifierToRemove);
    this.clearRemovedLayers(group, 'images', identifierToRemove);
    // const previewLayers = group
    //   .getLayers()
    //   .getArray()
    //   .filter(_layer => _layer.get('name').startsWith(`previews:${identifierToRemove}`));
    // if (previewLayers.length > 0) {
    //   for (const previewLayer of previewLayers) {
    //     group.getLayers().remove(previewLayer);
    //   }
    // }
    // // remove image full
    // const imageLayers = group
    //   .getLayers()
    //   .getArray()
    //   .filter(_layer => _layer.get('name').startsWith(`images:${identifierToRemove}`));
    // if (imageLayers.length > 0) {
    //   for (const imageLayer of imageLayers) {
    //     group.getLayers().remove(imageLayer);
    //   }
    // }
    const previewLayer = group
      .getLayers()
      .getArray()
      .find(_layer => _layer.get('name') === `previews:${metadataId}`);
    previewLayer && group.getLayers().remove(previewLayer);
    // remove image full
    const imagesNames = [`images:${metadataId}.pan`, `images:${metadataId}.ms`];
    const imageLayer = group
      .getLayers()
      .getArray()
      .find(_layer => imagesNames.includes(_layer.get('name')));
    imageLayer && group.getLayers().remove(imageLayer);
  }

  // TODO - rename toggleMetadataPreview() {
  // createImageFeature(previews, feature) {
  public toggleImagePreview(imagesMetadata: ImageMetadata[], flag: boolean | undefined = true) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    //
    for (const md of imagesMetadata) {
      const layer = this._findChildLayerBy(
        group,
        l => l.get('name') === `previews:${md.identifier}`
      );
      if (!flag && layer) {
        group.getLayers().remove(layer);
      } else if (flag && !layer) {
        this.createImageFeature(md);
      }
    }
  }

  public clearImageFeatures(group: LayerGroup, mds: ImageMetadata[]) {
    for (const md of mds) {
      const layer = this._findChildLayerBy(
        group,
        item => item.get('name') === `previews:${md.identifier}`
      );
      layer && group.getLayers().remove(layer);
      // console.log('clear image feature');
    }
    return;
  }

  public createImageFeature(md: ImageMetadata) {
    const previews = md.previews;
    // tslint:disable-next-line: strict-type-predicates
    if (previews.length === 1 && previews[0].geometry === undefined) {
      previews[0].geometry = md.geometry;
    }

    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    this.clearImageFeatures(group, [md]);

    const layersCollection = new Collection<ImageLayer<ImageSource>>();
    const featureGroup = new LayerGroup({
      name: `previews:${md.identifier}`,
    });

    for (const preview of previews) {
      const vectorFeature = this.readGeometry(preview.geometry, 'WKT');
      const pro = this.getOlMap().getView().getProjection();
      const il = new ImageLayer({
        source: new Static({
          attributions: '© Роскосмос',
          url: preview.url,
          projection: pro,
          imageExtent: vectorFeature.getGeometry()!.getExtent(),
        }),
        visible: true,
        zIndex: 31,
      });
      layersCollection.push(il);
    }
    featureGroup.setLayers(layersCollection);
    // this.getOlMap().addLayer(featureGroup);
    group.getLayers().push(featureGroup);
    // this.mapInstance.addLayer(featureGroup);
    // this.mapInstance.getView().fit(feature.getGeometry().getExtent())
  }

  public getSwipeImageLayer(
    imagesMetadata: ImageMetadataDetail[],
    bandCombination: BandCombinationType
  ) {
    const layersData = getLayersDataFromImageMetadata(imagesMetadata, bandCombination, false);
    const swipeImageLayers: BaseLayer[] = [];
    for (const layerData of layersData) {
      const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
      const layer = this._findChildLayerBy(group, item2 => item2.get('name') === layerData.name);
      if (layer) {
        swipeImageLayers.push(layer);
      }
    }
    return swipeImageLayers;
  }

  public getLayerById(layerName: string, layerGroup: LAYER_GROUPS) {
    const group = this._findLayerGroup(layerGroup);
    const layer = this._findChildLayerBy(group, item => item.get('id') === layerName);
    return layer;
  }

  public removeLayerById(layerName: string, layerGroup: LAYER_GROUPS) {
    const group = this._findLayerGroup(layerGroup);
    group
      .getLayersArray()
      .slice()
      .forEach(layer => {
        if (layer && layer.get('id') === layerName) {
          group.getLayers().remove(layer);
        }
      });
  }

  public removeLayerByKey(layerName: string, key: string, layerGroup: LAYER_GROUPS) {
    const group = this._findLayerGroup(layerGroup);
    group
      .getLayersArray()
      .slice()
      .forEach(layer => {
        if (layer && layer.get(key) === layerName) {
          group.getLayers().remove(layer);
        }
      });
  }

  public removeMosaicLayers() {
    const group = this._findLayerGroup(LAYER_GROUPS.mosaicLayers);
    group
      .getLayersArray()
      .slice()
      .filter(layer => mosaicLayerIds.includes(layer.get('id')) || layer.get('id') === 'BBP_Geoton')
      .forEach(layer => group.getLayers().remove(layer));
  }

  public removeBrightnessTemperatureControl() {
    const btControl = this._olMap
      .getControls()
      .getArray()
      .find(ctrl => ctrl instanceof BrightnessTemperatureControl);
    this._olMap.removeControl(btControl!);
  }

  public toggleMosaicLayer(layerData: any, properties?: any, mosaicUrls?: string[]) {
    if (layerData) {
      if (layerData.id === 'MSUGS_BT9') {
        const brightnessTemperatureControl = new BrightnessTemperatureControl({});
        this._olMap.addControl(brightnessTemperatureControl);
      } else {
        this.removeBrightnessTemperatureControl();
      }
      if (layerData.id === 'MSUGS_A_EL') {
        const previousUrl = new URL(layerData.config.options.urls);
        if (layerData.id !== 'BBP_Geoton' && properties) {
          previousUrl.searchParams.set('datetime', properties.datetime);
        }
        layerData.config.options.urls = [decodeURI(previousUrl.href)];
      } else {
        if (mosaicUrls && mosaicUrls.length > 0) {
          // const imageKey = Object.keys(assets).find(
          //   asset => asset.startsWith('image.tif.ms.3857') || asset.startsWith('image.tif.bt.3857')
          // );
          // const mosaicLayerUrl = `${VTMS_URL}/tiles/{z}/{x}/{y}?url=${assets[imageKey!].href}`;

          // const previousUrl = new URL(layerData.config.options.url);
          // if (layerData.id !== 'MSUGS_A_EL' && properties) {
          //   previousUrl.searchParams.set('platforms', properties.platform);
          // }
          // if (assets) {
          //   const imageKey = Object.keys(assets).find(asset => asset.startsWith('image.tif.ms.3857'));
          //   if (layerData.id === 'MSUMR_D' && assets && imageKey) {
          //     previousUrl.searchParams.set('asset_key', imageKey);
          //   }
          // }
          // if (layerData.id !== 'BBP_Geoton' && properties) {
          //   previousUrl.searchParams.set('datetime', properties.datetime);
          // }
          // layerData.config.options.url = decodeURI(previousUrl.href);
          // layerData.config.options.url = previousUrl.href;
          layerData.config.options.urls = mosaicUrls;
        }
      }
      const layer = this.getLayerById(layerData.id, LAYER_GROUPS.mosaicLayers);
      layer
        ? this.updateMosaicLayer(layerData, layerData.config.options.urls)
        : this.addMosaicLayer(layerData);
    }
  }

  public updateMosaicLayer(layerData: any, param: string[]) {
    const layer = this.getLayerById(layerData.id, LAYER_GROUPS.mosaicLayers) as TileLayer;
    const source = layer.getSource();
    // @ts-ignore
    source?.setUrls(param);
    debug.log('updateMosaicLayer', layer);
  }

  public toggleTopLayer(layerData?: any) {
    if (layerData) {
      const topLayer = this.getLayerById(layerData.id, LAYER_GROUPS.topLayers);
      topLayer
        ? this.removeLayerById(layerData.id, LAYER_GROUPS.topLayers)
        : this.addTopLayer(layerData);
    }
  }

  public toggleViewFullImage(layerData: any) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(group, item2 => item2.get('name') === layerData.name);
    if (layer) {
      group.getLayers().remove(layer);
      return;
    }
    // добавляем слой с тайлами на карту
    layer = this._parseLayer(layerData);
    this.addUserLayer(layer);
  }

  public toggleImageFullRes(
    imagesMetadata: ImageMetadataDetail[],
    bandCombination: BandCombinationType,
    flag: boolean,
    isPyramid: boolean
  ) {
    const layersData = getLayersDataFromImageMetadata(imagesMetadata, bandCombination, isPyramid);
    layersData.forEach(layerData => this.toggleViewFullImage(layerData));
  }

  public toggleProductPreviewLayer(identifier: string, url: string, bbox: Extent) {
    const layerData = {
      id: `productPreview:${identifier}`,
      name: `productPreview:${identifier}`,
      config: {
        options: {
          url: url,
          visibility: true,
          attribution: '© Роскосмос',
          //
          extent: bbox,
        },
      },
      type: 'XYZ',
    };

    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(group, item2 => item2.get('name') === layerData.name);
    if (layer) {
      group.getLayers().remove(layer);
      return;
    }

    layer = this._parseLayer(layerData);
    debug.log('toggleProductPreviewLayer', layer);
    this.addUserLayer(layer);

    // this.getOlMap().getView().fit(bbox, { maxZoom: 12 });
    this.getOlMap().getView().fit(bbox);
  }

  // metadata, vector layer
  public addMetadataResults(results: ImageMetadata[], append = false) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(
      group,
      item => item.get('name') === 'metadataLayer'
    ) as VectorLayer;
    if (!layer) {
      const bundle: CreateLayerBundle = {
        id: '666',
        name: 'metadataLayer',
        options: { url: '', zIndex: 31 },
      };
      layer = createSimpleVectorLayer(bundle);
      this.addUserLayer(layer);
    }
    if (!append) {
      // clear vector features
      layer.getSource()!.clear();
      // remove image previews
      const previewLayers = group
        .getLayers()
        .getArray()
        .filter(_layer => _layer.get('name').startsWith('previews'));
      for (const previewLayer of previewLayers) {
        group.getLayers().remove(previewLayer);
      }
      // remove image full
      const imageLayers = group
        .getLayers()
        .getArray()
        .filter(_layer => _layer.get('name').startsWith('images'));
      for (const imageLayer of imageLayers) {
        group.getLayers().remove(imageLayer);
      }
    }

    const features: any = [];
    results.forEach(item => {
      // проверка на "пересечение" с полюсами
      if (item.poleAreaIntersection === null || item.poleAreaIntersection === undefined) {
        const _feature = this._parseFeature(item);
        _feature && features.push(_feature);
      }
    });
    layer.getSource()!.addFeatures(features);
  }

  public ensureCurrentRegionLayer(layerName: string = 'regionLayer', zIndex: number = 90) {
    const group = this._findLayerGroup(LAYER_GROUPS.userLayers);
    let layer = this._findChildLayerBy(
      group,
      item => item.get('name') === layerName
    ) as VectorLayer;
    if (!layer) {
      const bundle: CreateLayerBundle = {
        id: layerName,
        name: layerName,
        options: { url: '', zIndex: zIndex },
      };
      layer = createSimpleVectorLayer(bundle);
      this.addUserLayer(layer);
    }
    return layer;
  }

  private _addLayerToGroup(groupName: LAYER_GROUPS, value: any) {
    const group = this._findLayerGroup(groupName);
    // group.addLayer(this._parseLayer(layer));

    group.getLayers().push(this._parseLayer(value));
  }

  public addImageLayer(layer: BaseLayer) {
    this._addLayerToGroup(LAYER_GROUPS.imageLayers, layer);
  }

  public addMosaicLayer(layer: BaseLayer) {
    this._addLayerToGroup(LAYER_GROUPS.mosaicLayers, layer);
  }

  public addUserLayer(layer: BaseLayer) {
    this._addLayerToGroup(LAYER_GROUPS.userLayers, layer);
  }

  public addTopLayer(layer: BaseLayer) {
    this._addLayerToGroup(LAYER_GROUPS.topLayers, layer);
  }
}

export default GeoportalMap;

import { t, Trans } from '@lingui/macro';
import React, { useEffect } from 'react'; // { SyntheticEvent }
import { useDispatch, useSelector } from 'react-redux';

import QuestionIcon from '@geobank/assets/src/icons/QuestionIcon';
import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
import { getMessages, readMessages } from 'ducks/messageNotify'; // fetchMessages
import { getTasks } from 'ducks/metadataDownload';
import MessageTask from './MessageTask';

import { withI18n, withI18nProps } from '@lingui/react';
import styles from './MessagesButton.module.scss';

const MessagesButton: React.FC<withI18nProps> = props => {
  const { i18n } = props;
  const messages = useSelector(getMessages);
  const tasks = useSelector(getTasks);
  const dispatch = useDispatch();

  const totalCount = messages.length + tasks.length;

  useEffect(() => {
    // dispatch(fetchMessages.request('metadata_download'));
    // eslint-disable-next-line
  }, []);

  const handleReadMessage = (messageId: number) => {
    dispatch(readMessages.request(messageId));
  };

  let content;
  // if (messages.length === 0 && tasks.length === 0) {
  if (totalCount === 0) {
    content = (
      <div className={styles.emptyText}>
        <Trans>Список сообщений пуст</Trans>
      </div>
    );
  } else {
    content = (
      <CustomScrollBarContainer heightMax={`calc(42vh)`} color="#CEEAFB">
        {tasks.map(task => (
          <Dropdown.MenuItem key={task.taskUuid}>
            {/* Задание создано ({task.orderDate}) */}
            <MessageTask
              // @ts-ignore
              data={{
                message: 'Задание создано',
                createdAt: task.orderDate,
              }}
            />
          </Dropdown.MenuItem>
        ))}
        {tasks.length > 0 && messages.length > 0 && <Dropdown.MenuDivider />}
        {messages.map(message => (
          <Dropdown.MenuItem key={message.id}>
            <MessageTask data={message} onReadMessage={handleReadMessage} />
          </Dropdown.MenuItem>
        ))}
      </CustomScrollBarContainer>
    );
  }

  return (
    <Dropdown dropup={true} pullRight={true}>
      <Dropdown.Toggle title={i18n._(t`Уведомления`)}>
        <QuestionIcon />
        {totalCount > 0 && (
          <div className={styles.counterWrap}>
            <span className={styles.counterValue}>{totalCount >= 99 ? 99 : totalCount}</span>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.listContainer}>{content}</Dropdown.Menu>
    </Dropdown>
  );
};

export default withI18n()(MessagesButton);

// from ProductParametersForm
// OutputProductParameters, ProductParametersForm
import { Trans } from '@lingui/macro';
// import { i18nMark } from '@lingui/react';
import classNames from 'classnames';
// import { connect } from 'react-redux';
import get from 'lodash/get';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip-lite';
// import { RootState } from 'ducks';
// import { updateCurrentCartParams, getCurrentCartParams } from 'ducks/order';

import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CheckBox from 'components/common/forms/CheckBox/CheckBox';
// import CheckBoxGroupOld from 'components/forms/CheckBoxGroup';
import CheckBoxGroup from 'components/common/forms/CheckBox/CheckBoxGroup';
import { CheckBoxChangeEvent } from 'components/common/forms/CheckBox/interface';
import { RadioChangeEvent } from 'components/common/forms/Radio/interface';
import Radio from 'components/common/forms/Radio/Radio';
import RadioGroup from 'components/common/forms/Radio/RadioGroup';
import HelpIcon from 'components/common/HelpIcon/HelpIcon';
import SpectralChannelsField from '../../RequestProductPanel/fields/SpectralChannelsFieldNext';

import DrawControlNext /*, {
  DrawControlNext as DrawControlNextOrigin,
}*/ from 'components/map/DrawControl/DrawControlNext';
// import API from 'api';
import GeoportalMap from 'components/map/Map/Map';
import { geoJSONToWKTAndTransform } from 'components/map/Map/utils';
import { formatCurrency } from 'components/utils/format';
import { getCroppingPolygonIntersection } from 'components/utils/validation/checkIntersections';
import { ImageMetadata } from 'ducks/metadata';
import { CartImage, ProductPMS, QuotationRequestParams } from 'ducks/order';
import * as orderTypes from 'ducks/types/orderTypes';
import { MultiPolygon } from 'ol/geom';
import * as productOptions from './productOptions';
import {
  isAdditionalOptionsAvailable,
  isBandsSelectAvailable,
  isBundleSelectAvailable,
  isGeometryCroppingAvailable,
  isPanhsarpSelectAvailable,
} from './utils';

import styles from './OrderImageForm.module.scss';

interface OrderImageFormProps {
  // data: { [key: string]: string | number };
  // onChange: (data: any) => void;
  // instrument: string;
  orderImage: ImageMetadata; // ImageMetadata | ImageMetadata[];
  regionsOfInterest: GeoJSONFeature[];
  onCancel: () => void;
  onAddToCart: (cartImages: orderTypes.CartImage[]) => void;
  onUpdateCartParams?: (params: any) => void;
  getPrice: (data: QuotationRequestParams) => void;
  isGeofenced?: boolean;
}

interface OrderImageState {
  // channelsUnion: 'union' | 'separate' | 'both';
  additionalOptions: { [key: string]: boolean };
  geometryCropping: 'region' | 'whole' | 'fragment';
  // bands: string[];

  price?: number;
  isDrawShow: boolean;
  drawGeometryFeature?: GeoJSONFeature;

  // product image (like sensor [with some bands])
  productPMS: ProductPMS; // 'pansharp' | 'bundle' | 'ms' | 'pan';
  msChannels: { [key: string]: boolean };
}

// const mapStateToProps = (state: RootState) => ({
//   params: getCurrentCartParams(state),
// });
// const mapDispatchToProps = {
//   updateCurrentCartParamsRequest: updateCurrentCartParams.request,
// };

class OrderImageForm extends PureComponent<
  // ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OrderImageFormProps
  OrderImageFormProps,
  OrderImageState
> {
  public drawRef: React.RefObject<any>; // React.RefObject<DrawControlNextOrigin> // HOC withI18n =((
  public hasPanchromaticSensor: boolean;
  public hasMultiSpectrSensor: boolean;

  public state: OrderImageState = {
    // channelsUnion: 'union',
    additionalOptions: { elevation: false },
    geometryCropping: this.props.isGeofenced ? 'region' : 'whole',
    // bands: [],

    price: undefined,
    isDrawShow: false,
    drawGeometryFeature: undefined,

    productPMS: 'pansharp',
    msChannels: {},
  };

  constructor(props: any) {
    super(props);
    this.drawRef = React.createRef();

    this.hasPanchromaticSensor =
      this.props.orderImage.sensors.find(sensor => sensor.isPanchromatic) !== undefined;
    this.hasMultiSpectrSensor =
      this.props.orderImage.sensors.find(sensor => sensor.isPanchromatic === false) !== undefined;
  }

  // private handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const fieldName = event.target.name;
  //   const value = event.target.value;
  //   // @ts-ignore
  //   // this.props.updateCurrentCartParamsRequest({ [fieldName]: value });

  //   this.props.onChange({ ...this.props.data, [fieldName]: value });
  // };

  // handleRadioInputChange = (fieldName: string, value: string) => {
  //   // @ts-ignore
  //   this.setState({ [fieldName]: value });
  // };

  // productPMS
  public handleProductInputChange = (event: RadioChangeEvent) => {
    const { name: fieldName, value } = event.target;
    // @ts-ignore
    this.setState({ [fieldName]: value });
  };

  // msChannels
  public handleChannelsInputChange = (e: CheckBoxChangeEvent) => {
    const { name: fieldName, value, checked } = e.target;
    // @ts-ignore
    this.setState({ [fieldName]: { ...this.state[fieldName], [value]: checked } });
  };

  public handleRadioInputChange = (event: RadioChangeEvent) => {
    const { name: fieldName, value } = event.target;
    // @ts-ignore
    // this.setState({ [fieldName]: value, isDrawShow: value === 'fragment' ? true : false });
    const newState: OrderImageState = { [fieldName]: value }; // Partial<OrderImageState>
    if (value === 'fragment') {
      newState.isDrawShow = true;
    } else if (value === 'region' && this.props.regionsOfInterest.length > 0) {
      newState.drawGeometryFeature = this.props.regionsOfInterest[0];
    } else {
      newState.drawGeometryFeature = undefined;
    }
    this.setState(newState);
  };

  public handleCheckBoxInputChangeOld = (fieldName: string, value: string) => {
    this.setState(prevState => {
      // @ts-ignore
      const newValue: string[] = [...prevState[fieldName]];
      const index = newValue.indexOf(value);
      if (index > -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(value);
      }

      return { ...prevState, [fieldName]: newValue };
    });
  };

  public handleCheckBoxInputChange = (e: CheckBoxChangeEvent) => {
    const { name: fieldName, value, checked } = e.target;
    // @ts-ignore
    this.setState({ [fieldName]: { ...this.state[fieldName], [value]: checked } });
  };

  // getBytesPerPixelOptions = () => {};

  public handleClipCancel = () => {
    this.drawRef.current?.getWrappedInstance().clearRegion();
    this.setState({
      isDrawShow: false,
      drawGeometryFeature: this.props.isGeofenced ? this.props.regionsOfInterest[0] : undefined,
      geometryCropping: this.props.isGeofenced ? 'region' : 'whole',
    });
    /* if (this.props.regionsOfInterest.length > 0) {
      geometryCropping = 'region';
      drawGeometryFeature = this.props.regionsOfInterest[0];
    } */
  };

  public handleClipSubmit = () => {
    // оставляем контур для наглядности до закрытия формы добавления снимка
    // this.drawRef.current?.getWrappedInstance().clearRegion();
    this.setState({ isDrawShow: false });
  };

  public updatePrice() {
    const { orderImage } = this.props; // regionsOfInterest
    const { additionalOptions, geometryCropping, drawGeometryFeature, productPMS } = this.state;

    const params: QuotationRequestParams = {
      bandCombination: productPMS,
      elevation: get(additionalOptions, 'elevation', false),
      // pansharpening: get(additionalOptions, 'pan-sharpening', false),
      images: [
        {
          metadataIdentifier: orderImage.identifier, // 'ETRIS.KV1.PSS.5700.3.0.2014-07-02.L0.NTSOMZ_MSK',
        },
      ],
      callback: (result: { totalPrice: number } | Error) => {
        if (result instanceof Error) {
          // && geometryCropping === 'fragment'
          // this.setState({ geometryCropping: 'whole' });
          this.setState({ drawGeometryFeature: undefined });
        } else {
          this.setState({ price: result.totalPrice });
        }
      },
    };
    if (drawGeometryFeature && (geometryCropping === 'fragment' || geometryCropping === 'region')) {
      params.croppingPolygon = geoJSONToWKTAndTransform(drawGeometryFeature); // 'POLYGON ((30 10, 40 40, 20 40, 10 20, 30 10))',
    }
    // else if (geometryCropping === 'region' && regionsOfInterest.length > 0) {
    //   params.cropping_polygon = geoJSONToWKTAndTransform(regionsOfInterest[0]);
    // }

    this.props.getPrice(params);
    ///
    // подумать, как бы прогонять запрос через сагу, чтобы было обновление сессии при её протухании
    /* API.getPrice(params)
      .then(resp => resp.data)
      .then(data => this.setState({ price: data.total_price }))
      .catch(err => console.log(err)); */
  }

  public handleCancel = () => {
    this.props.onCancel();

    //
    this.clearRegion();
    //
  };

  // TODO - исправить дублирование кода получение настроек
  public handleAddToCart = () => {
    const { orderImage } = this.props; // regionsOfInterest
    const { geometryCropping, price, drawGeometryFeature, productPMS, msChannels } = this.state;
    const bands: string[] = [];
    let bandsTotal = 0;
    Object.entries(msChannels).forEach(([key, value]) => {
      bandsTotal++;
      value === true && bands.push(key);
    });
    // ! Костыль для Цифровой Земли
    let croppingPolygon: Array<string | undefined> = [];
    if (orderImage.identifier.startsWith('APOI')) {
      if (drawGeometryFeature) {
        const croppingPolygonIntersection = getCroppingPolygonIntersection(
          geoJSONToWKTAndTransform(drawGeometryFeature),
          orderImage.geometry
        );
        if (croppingPolygonIntersection?.geometry.type === 'MultiPolygon') {
          const multiCroppingPolygon = new MultiPolygon(
            croppingPolygonIntersection.geometry.coordinates
          );
          croppingPolygon = multiCroppingPolygon
            .getPolygons()
            .map(polygon => new WKT().writeGeometry(polygon));
        } else {
          croppingPolygon?.push(geoJSONToWKTAndTransform(croppingPolygonIntersection!));
        }
      } else {
        croppingPolygon?.push(orderImage.geometry);
      }
    } else if (drawGeometryFeature && ['fragment', 'region'].includes(geometryCropping)) {
      croppingPolygon?.push(geoJSONToWKTAndTransform(drawGeometryFeature));
    }
    if (croppingPolygon.length === 0) {
      croppingPolygon.push(undefined);
    }
    const cartImages: orderTypes.CartImage[] = [];
    croppingPolygon.forEach((polygon, index) => {
      cartImages.push({
        bandCombination: productPMS,
        metadataIdentifier: index > 0 ? `${orderImage.identifier}:${index}` : orderImage.identifier,
        geometry: orderImage.geometry,
        croppingPolygon: polygon,
        price: price || 0,
      });
      if (productPMS === 'ms' && bands.length > 0 && bands.length !== bandsTotal) {
        cartImages[index].bands = bands;
      }
    });
    this.props.onAddToCart(cartImages);
    this.clearRegion();
  };

  // this.drawRef.current?.getWrappedInstance().clearRegion();
  // TODO - <DrawControl> не убирать, и через него работать с геометрией
  // clearClipRegion() {}
  public clearRegion() {
    const map = GeoportalMap.getInstance();
    const layer = map.ensureCurrentRegionLayer('regionLayerNext');
    layer.getSource()?.clear();
  }

  public componentDidMount() {
    let geometryCropping: 'region' | 'whole' | 'fragment' = this.state.geometryCropping; // 'whole';
    let drawGeometryFeature: GeoJSONFeature | undefined;
    if (this.props.isGeofenced || this.props.regionsOfInterest.length > 0) {
      geometryCropping = isGeometryCroppingAvailable(this.props.orderImage) ? 'region' : 'whole';
      drawGeometryFeature = this.props.regionsOfInterest[0];
    }

    let productPMS: ProductPMS = this.state.productPMS; // 'pansharp';
    // if (prevProps.orderImages.sensors !== this.props.orderImages.sensors) {
    if (
      this.hasPanchromaticSensor &&
      this.hasMultiSpectrSensor &&
      isPanhsarpSelectAvailable(this.props.orderImage)
    ) {
      // this.setState({ productPMS: 'pansharp' });
      productPMS = 'pansharp';
    } else if (this.hasPanchromaticSensor && !this.hasMultiSpectrSensor) {
      // this.setState({ productPMS: 'pan' });
      productPMS = 'pan';
    } else if (
      (!this.hasPanchromaticSensor && this.hasMultiSpectrSensor) ||
      !isPanhsarpSelectAvailable(this.props.orderImage)
    ) {
      // this.setState({ productPMS: 'ms' });
      productPMS = 'ms';
    }
    // }

    let additionalOptions = this.state.additionalOptions;
    // no BBP.
    if (this.props.orderImage.identifier.startsWith('ETRIS.')) {
      additionalOptions = {
        ...additionalOptions,
        elevation: this.props.orderImage.identifier.startsWith('ETRIS.'),
      };
    }

    if (
      this.state.productPMS === productPMS &&
      // this.state.geometryCropping === geometryCropping
      this.state.drawGeometryFeature === drawGeometryFeature &&
      this.state.additionalOptions === additionalOptions
    ) {
      this.updatePrice();
    }
    this.setState(
      {
        additionalOptions,
        geometryCropping,
        drawGeometryFeature,
        productPMS,
      }
      // , () => { /* тут уже поменяный state, сравнение для updatePrice() бессмысленно */ }
    );
  }

  public componentDidUpdate(prevProps: OrderImageFormProps, prevState: OrderImageState) {
    if (
      prevState.drawGeometryFeature !== this.state.drawGeometryFeature ||
      prevState.additionalOptions !== this.state.additionalOptions ||
      prevState.productPMS !== this.state.productPMS
    ) {
      this.updatePrice();
    }

    if (prevState.geometryCropping === 'fragment' && this.state.geometryCropping !== 'fragment') {
      //
      this.clearRegion();
      //
    }
  }

  public render() {
    const {
      /* channelsUnion, */
      additionalOptions,
      geometryCropping,
      // bands,
      price,
      isDrawShow,
      drawGeometryFeature,
      productPMS,
      msChannels,
    } = this.state;
    const { orderImage } = this.props;

    if (isDrawShow) {
      return (
        <div className={styles.drawRoot}>
          <DrawControlNext
            ref={this.drawRef}
            isShow={true}
            onDrawEnd={value => this.setState({ drawGeometryFeature: value })} // handleDrawSubmit
            onClear={() => this.setState({ drawGeometryFeature: undefined })} // handleDrawClear
          />

          {/* #mapRegionSelector .clip-toolbar-info */}
          <div className={styles.drawInfoContainer}>
            <div className={styles.drawInfoTitle}>
              <span>
                <Trans id="order_image.draw_info_title">Укажите фрагмент снимка</Trans>
              </span>
              <Tooltip
                tagName="span"
                styles={{ marginLeft: '4px' }}
                tipContentClassName="tip-content--border-grey tip-content--width-280"
                arrow={false}
                background="white"
                color="black"
                direction="down" // up", "down", "left", "right". "left-start", "end", "middle"
                content={
                  // <Trans>
                  <span>
                    На панели слева выберите форму фрагмента (прямоугольник или многоугольник) и
                    нарисуйте фрагмент на фоне снимка последовательным нажатием левой кнопки мыши
                  </span>
                }
              >
                <HelpIcon />
              </Tooltip>
            </div>
            <div className={styles.drawInfoActions}>
              {/* <Button color={ButtonColor.TRANSPARENT} onClick={this.handleCancelClip}>Отменить</Button> */}
              <Button color={ButtonColor.LIGHT} onClick={this.handleClipCancel}>
                <Trans>Отменить</Trans>
              </Button>
              <Button onClick={this.handleClipSubmit} disabled={drawGeometryFeature === undefined}>
                <Trans>Подтвердить</Trans>
              </Button>
            </div>
          </div>
        </div>
      );
    }

    // console.log('orderImages.sensors', orderImages.sensors);

    return (
      <div className={styles.root}>
        {/* <div className={classNames(styles.header, styles.marginBottom)}>
          <Trans id="order_image.header_text">Поместить снимок в корзину</Trans>
        </div> */}
        <div className={classNames(styles.headerContainer, styles.marginBottom)}>
          <h3 className={styles.header2}>
            <Trans id="order_image.header_text">Поместить снимок в корзину</Trans>
          </h3>
          <div className={styles.stepLabel}>
            <Trans>Шаг 1 из 2</Trans>
          </div>
        </div>

        {/* <div className="paramContainer content marginBottom"> */}
        <div>
          <div className={styles.paramTitle}>
            <span>
              <Trans>Параметры выдачи</Trans>
            </span>
          </div>
          <div className={styles.paramField}>
            {/* test */}
            <RadioGroup
              name="productPMS"
              value={productPMS}
              onChange={this.handleProductInputChange}
            >
              {this.hasPanchromaticSensor &&
                this.hasMultiSpectrSensor &&
                isPanhsarpSelectAvailable(orderImage) && (
                  <Radio value="pansharp">
                    {/* <Trans id="image_product.value_pansharp">Паншарп-изображение</Trans> */}
                    {productOptions.pansharpTitle}
                    {/* <productOptions.PansharpTitle /> */}
                  </Radio>
                )}
              {this.hasPanchromaticSensor &&
                this.hasMultiSpectrSensor &&
                isBundleSelectAvailable(orderImage) && (
                  <Radio value="bundle">
                    {/* <Trans id="image_product.value_bundle">BUNDLE-комплект (PAN + MS)</Trans> */}
                    {productOptions.bundleTitle}
                  </Radio>
                )}
              {this.hasMultiSpectrSensor && (
                <Radio value="ms">
                  {/* <Trans id="image_product.value_ms">Мультиспектральное изображение (MS)</Trans> */}
                  {productOptions.msTitle}
                </Radio>
              )}
              {/* Мультиспектральные каналы */}
              {productPMS === 'ms' && (
                <div className={styles.marginLeft}>
                  <SpectralChannelsField
                    sensors={orderImage.sensors}
                    // checked={bands}
                    checked={msChannels}
                    onChange={checked =>
                      this.setState({
                        msChannels: checked,
                        // bands: Object.keys(checked)
                        //   .filter(key => checked[key] === true)
                        //   .map(key => key),
                      })
                    }
                    // TODO - отметить все пункты, если выбор не доступен
                    disabled={!isBandsSelectAvailable(orderImage)}
                  />
                </div>
                // <CheckBoxGroup
                //   className={styles.marginLeft}
                //   name="msChannels"
                //   value={msChannels}
                //   onChange={this.handleChannelsInputChange}
                // >
                //   <CheckBox value="yyy" multiline>
                //     <Trans id="order_image.value_pan-sharpening">
                //       Сделать паншарп-изображение <br />
                //       (в отдельном TIFF-файле)
                //     </Trans>
                //   </CheckBox>
                //   <CheckBox value="xxx" multiline>
                //     dsadasd
                //   </CheckBox>
                // </CheckBoxGroup>
              )}
              {/* --- end --- */}
              {this.hasPanchromaticSensor && (
                <Radio value="pan">
                  {/* <Trans id="image_product.value_pan">Панхроматическое изображение (PAN)</Trans> */}
                  {productOptions.panTitle}
                </Radio>
              )}
            </RadioGroup>
          </div>

          {false && isAdditionalOptionsAvailable(orderImage) && (
            <div className={styles.paramField}>
              <CheckBoxGroup
                name="additionalOptions"
                value={additionalOptions}
                onChange={this.handleCheckBoxInputChange}
              >
                <CheckBox value="elevation" /* value="no-elevation" */>
                  {/* Ортотрансформировать <br />с использованием ЦМР */}
                  <Trans id="order_image.value_elevation">Использовать ЦМР</Trans>
                  <Tooltip
                    tagName="span"
                    styles={{ marginLeft: '4px' }}
                    tipContentClassName="tip-content--border-grey"
                    arrow={false}
                    background="white"
                    color="black"
                    direction="right"
                    content={
                      <Trans id="order_image.hint_elevation">
                        Применять цифровую модель рельефа
                      </Trans>
                    }
                  >
                    <HelpIcon />
                  </Tooltip>
                </CheckBox>
              </CheckBoxGroup>
            </div>
          )}
        </div>

        {/* {isGeometryCroppingAvailable(orderImages) && (
          <> 
        */}
        <div className={styles.delimeter} />

        <div>
          <div className={styles.paramTitle}>
            <span>
              {/* Геометрия снимков */}
              <Trans id="order_image.title_geometry">Геометрия</Trans>
            </span>
          </div>
          {isGeometryCroppingAvailable(orderImage) ? (
            <div className={styles.paramField}>
              <RadioGroup
                name="geometryCropping"
                value={geometryCropping}
                onChange={this.handleRadioInputChange}
              >
                <Radio value="region" disabled={this.props.regionsOfInterest.length === 0}>
                  <Trans id="order_image.value_geometry_region">Обрезать по району интереса</Trans>
                  {this.props.regionsOfInterest.length === 0 && (
                    <Tooltip
                      tagName="span"
                      styles={{ marginLeft: '4px' }}
                      tipContentClassName="tip-content--border-grey"
                      arrow={false}
                      background="white"
                      color="black"
                      direction="right"
                      content={
                        <Trans id="order_image.hint_geometry_region">
                          Требуется указать геометрию при поиске
                        </Trans>
                      }
                    >
                      <HelpIcon />
                    </Tooltip>
                  )}
                </Radio>
                {!this.props.isGeofenced && (
                  <Radio value="whole">
                    <Trans id="order_image.value_geometry_whole">Добавить снимок целиком</Trans>
                  </Radio>
                )}
                <Radio value="fragment" /* disabled */>
                  <Trans id="order_image.value_geometry_fragment">Вырезать фрагмент</Trans>
                </Radio>
              </RadioGroup>
            </div>
          ) : (
            <div className={styles.paramField}>
              <span style={{ color: 'grey', fontSize: '14px' }}>
                Снимок доступен для заказа только целиком
              </span>
            </div>
          )}
        </div>

        <div className={styles.delimeter} />

        <div style={{ marginBottom: '10px' }}>
          {price !== undefined ? (
            <div className={styles.priceContainer}>
              <Trans id="order_image.text_cost">
                Стоимость: <span>{formatCurrency(price)}</span>
              </Trans>
            </div>
          ) : (
            <div className={styles.priceContainer}>
              <Trans id="order_image.text_cost_pending">Идёт расчёт стоимости ...</Trans>
            </div>
          )}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ color: 'grey', fontSize: '14px' }}>{/* some text */}</span>
        </div>
        <div className={classNames(styles.actionsContainer)}>
          <Button color={ButtonColor.WHITE} width={'150px'} onClick={this.handleCancel}>
            <Trans>Отменить</Trans>
          </Button>
          <Button color={ButtonColor.DEFAULT} width={'150px'} onClick={this.handleAddToCart}>
            <Trans>Добавить в корзину</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(OrderImageForm);
export default OrderImageForm;

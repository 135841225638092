import { createActionCreator, createReducer } from 'deox'; //  getType, ActionType
import { combineReducers } from 'redux';

import { LANGUAGE_CODE } from 'components/map/Map/Map';
import get from 'lodash/get';
import { Extent } from 'ol/extent';
import { createSelector } from 'reselect';
import * as mapLayersTypes from './types/mapLayersTypes';

import { RootState as RootStateType } from './root';

/**
 * Actions
 */
export const fetchLayers = {
  // METADATA_SEARCH_REQUEST
  request: createActionCreator('LAYERS/FETCH_REQUEST'),
  success: createActionCreator('LAYERS/FETCH_SUCCESS', resolve => (data: any[], locale: string) => {
    // развернуть капусту =)
    const layers = data[0].templates[1].layers;
    const baseYandexLayerUrl = new URL(layers[1].config.options.url);
    const topYandexLayerUrl = new URL(layers[8].config.options.url);
    baseYandexLayerUrl.searchParams.set('lang', LANGUAGE_CODE[locale]);
    topYandexLayerUrl.searchParams.set('lang', LANGUAGE_CODE[locale]);
    layers[1].config.options.url = decodeURI(baseYandexLayerUrl.href);
    layers[8].config.options.url = decodeURI(topYandexLayerUrl.href);
    return resolve(layers as mapLayersTypes.Layer[]);
  }),
  failure: createActionCreator('LAYERS/FETCH_FAILURE', resolve => error => resolve(error)),
};

// ~ createAction()
export const toggleBaseLayer = createActionCreator(
  'LAYERS/TOGGLE_BASE_LAYER',
  resolve => (baseLayerId: string) => resolve(baseLayerId)
);

export const toggleMosaicLayer = createActionCreator(
  'LAYERS/TOGGLE_MOSAIC_LAYER',
  resolve => (mosaicLayerId: string) => resolve(mosaicLayerId)
);

export const changeTopLayerUrl = createActionCreator(
  'LAYERS/CHANGE_TOP_LAYER_URL',
  resolve => (locale: string) => resolve(locale)
);

export const toggleTopLayer = createActionCreator(
  'LAYERS/TOGGLE_TOP_LAYER',
  resolve => (topLayerId: string) => resolve(topLayerId)
);

export const addTopLayer = createActionCreator(
  'LAYERS/ADD_TOP_LAYER',
  resolve => (topLayerId: string) => resolve(topLayerId)
);

export const removeTopLayer = createActionCreator(
  'LAYERS/REMOVE_TOP_LAYER',
  resolve => (topLayerId: string) => resolve(topLayerId)
);

export const toggleProductPreviewLayer = createActionCreator(
  'LAYERS/TOGGLE_PRODUCT_PREVIEW_LAYER',
  resolve => (data: { identifier: string; url: string; bbox: Extent }) => resolve(data)
);

/**
 * Reducers and state
 */
export type LayersState = mapLayersTypes.Layer[];

export const defaultLayersState: LayersState = [];

export const layersReducer = createReducer(defaultLayersState, handleAction => [
  handleAction(fetchLayers.success, (_, { payload }) => payload),
  handleAction(changeTopLayerUrl, (state, { payload }) => {
    const layers = state;
    const baseYandexLayerUrl = new URL(layers[1].config.options.url);
    const topYandexLayerUrl = new URL(layers[8].config.options.url);
    baseYandexLayerUrl.searchParams.set('lang', LANGUAGE_CODE[payload]);
    topYandexLayerUrl.searchParams.set('lang', LANGUAGE_CODE[payload]);
    layers[1].config.options.url = decodeURI(baseYandexLayerUrl.href);
    layers[8].config.options.url = decodeURI(topYandexLayerUrl.href);
    return layers;
  }),
]);

export const defaultIsFetchingState = false;

export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
  handleAction(fetchLayers.request, () => true),
  handleAction([fetchLayers.success, fetchLayers.failure], () => false),
]);

export const selectedBaseLayerDefaultState: string = 'Yandex';

export const selectedBaseLayerIdReducer = createReducer(
  selectedBaseLayerDefaultState,
  handleAction => [handleAction(toggleBaseLayer, (_, { payload }) => payload)]
);

export const selectedMosaicLayerIdDefaultState: string = '';

export const selectedMosaicLayerIdsReducer = createReducer(
  selectedMosaicLayerIdDefaultState,
  handleAction => [handleAction(toggleMosaicLayer, (_, { payload }) => payload)]
);

export const selectedTopLayerIdDefaultState: string[] = [];

export const selectedTopLayerIdReducer = createReducer(
  selectedTopLayerIdDefaultState,
  handleAction => [
    handleAction(toggleTopLayer, (state, { payload }) => {
      let selectedTopLayers: string[] = state;
      selectedTopLayers.includes(payload)
        ? (selectedTopLayers = selectedTopLayers.filter(layer => layer !== payload))
        : (selectedTopLayers = [...selectedTopLayers, payload]);
      return selectedTopLayers;
    }),
    handleAction(addTopLayer, (state, { payload }) => {
      let selectedTopLayers: string[] = state;
      selectedTopLayers = [...selectedTopLayers, payload];
      return selectedTopLayers;
    }),
    handleAction(removeTopLayer, (state, { payload }) => {
      let selectedTopLayers: string[] = state;
      selectedTopLayers = selectedTopLayers.filter(layer => layer !== payload);
      return selectedTopLayers;
    }),
  ]
);

// layersRootReducer
export default combineReducers({
  layers: layersReducer,
  isFetching: isFetchingReducer,
  selectedBaseLayerId: selectedBaseLayerIdReducer,
  selectedMosaicLayerId: selectedMosaicLayerIdsReducer,
  selectedTopLayerId: selectedTopLayerIdReducer,
});

/**
 * Selectors
 */
export const getLayers = (state: RootStateType) => state.layers.layers;
export const getIsFetching = (state: RootStateType) => state.layers.isFetching;
export const getSelectedBaseLayerId = (state: RootStateType) => state.layers.selectedBaseLayerId;
export const getSelectedMosaicLayerId = (state: RootStateType) =>
  state.layers.selectedMosaicLayerId;
export const getSelectedTopLayerId = (state: RootStateType) => state.layers.selectedTopLayerId;

// with reselect
export const getBaseLayers = createSelector([getLayers], layers =>
  layers.filter(layer => get(layer, 'config.treeConfig.checkedGroup') === 'base')
);

export const getMosaicLayers = createSelector([getLayers], layers =>
  layers.filter(layer => get(layer, 'config.treeConfig.checkedGroup') === 'mosaic')
);

export const getTopLayers = createSelector([getLayers], layers =>
  layers.filter(layer => get(layer, 'config.treeConfig.checkedGroup') === 'top')
);

// TODO - active layer -> map-duck; and remove this selector, and use saga put selectBaseLayer
// export const getActiveBaseLayer = createSelector(
//   [getBaseLayers, (state: RootStateType) => state.layers.selectedBaseLayer],
//   (layers, selectedBaseLayerId) => {
//     let result;
//     if (selectedBaseLayerId) {
//       result = layers.find(layer => get(layer, 'id') === selectedBaseLayerId);
//     } else {
//       result = layers.find(
//         layer => get(layer, 'config.options.visible') || get(layer, 'config.options.visibility'),
//       );
//     }
//     return result;
//   },
// ); // Layer | undefined

// export const imageLayers = createSelector([getLayers], layers => {
//   // name: Снимки, Снимки ЦОД; type: Group;

//   const layerGroup = layers.find(layer => get(layer, 'name') === 'Мозаики'); // name == Снимки

//   // return get(layerGroup, 'layers', []).slice(0, 5);
//   const byId: { [id: string]: any } = {};
//   const allIds: string[] = [];
//   for (const layer of get(layerGroup, 'layers', [])) {
//     byId[layer.id] = layer;
//     allIds.push(layer.id);
//   }
//   // console.log('IMAGE_LAYERS_DUCKS', layers, layerGroup, byId, allIds)
//   return { byId, allIds };
// });
// getHelperLayers (вытаскивать по config.options.XXX) - Кадастровая карта и т.п.

// export const getImageLayers = createSelector([imageLayers], ({ byId, allIds }) => {
//   return allIds.map(id => byId[id]);
// });

// export const nameLayers = createSelector([getLayers], layers => {
//   // name: Снимки, Снимки ЦОД; type: Group;
//   const layerGroup = layers.find(layer => get(layer, 'name') === 'Скрыть названия'); // name == Снимки
//   // return get(layerGroup, 'layers', []).slice(0, 5);
//   const byId: { [id: string]: any } = {};
//   const allIds: string[] = [];
//   for (const layer of get(layerGroup, 'layers', [])) {
//     byId[layer.id] = layer;
//     allIds.push(layer.id);
//   }
//   return { byId, allIds };
// });
// getHelperLayers (вытаскивать по config.options.XXX) - Кадастровая карта и т.п.

// export const getImageLayersById = createSelector([imageLayers], ({ byId }) => byId);

// export const getNameLayerById = createSelector([nameLayers], ({ byId }) => byId);

// export const getSelectedImageLayers = createSelector(
//   [imageLayers, getSelectedImageLayerIds],
//   ({ byId: layersById }, selectedIds) => {
//     const result: any[] = selectedIds.map(id => layersById[id]);
//     return result;
//   }
// );

import React from 'react';

const EyeIconBW: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" version="1.1" id="svg6">
      <path
        opacity="0.3"
        d="m 0.65365359,9.8817624 c 0,0 2.56965401,-6.2813899 9.42208641,-6.2813899 5.139309,0 9.422086,6.2813899 9.422086,6.2813899 0,0 -4.282777,6.2813906 -9.422086,6.2813906 -6.8524324,0 -9.42208641,-6.2813906 -9.42208641,-6.2813906 z"
        fill="#5dbdf9"
        fillOpacity="1"
        strokeWidth="1.44928"
        id="path2480"
      />
      <path
        id="path3431"
        fill="#000000"
        fillOpacity="0.8"
        strokeWidth="1.97605"
        d="M 10.076172 5.5351562 C 7.7463963 5.5351562 5.8574219 7.4800973 5.8574219 9.8808594 C 5.8574219 11.091694 6.3381309 12.188347 7.1132812 12.976562 C 7.8750355 13.751158 8.9214374 14.228516 10.076172 14.228516 L 10.076172 5.5351562 z "
      />
      <path
        id="path3567"
        fill="#ffffff"
        fillOpacity="1"
        strokeWidth="1.97605"
        d="M 10.076172 5.5351562 L 10.076172 14.228516 C 11.244361 14.228516 12.300554 13.739809 13.064453 12.949219 C 13.824025 12.163105 14.294922 11.077826 14.294922 9.8808594 C 14.294922 7.4925874 12.424362 5.555265 10.111328 5.5351562 C 10.109128 5.5351372 10.107669 5.5351633 10.105469 5.5351562 C 10.095469 5.5351243 10.086092 5.5351562 10.076172 5.5351562 z "
      />
    </svg>
  );
};

export default EyeIconBW;

import { ImageSource } from 'ducks/types/orderTypes';

// productVariants
export const productOptionsMapperBySource = {
  etris: {
    options: {
      productCode: ['L1', 'L2'],
      srsName: ['EPSG:4326', 'UTM', 'EPSG:7683'],
      bytesPerPixel: [1, 2],
      elevation: [true, false],
    },
    combinations: [
      {
        productCode: 'L1',
        srsName: 'EPSG:4326',
        bytesPerPixel: null,
        elevation: true,
      },
      {
        productCode: 'L2',
        srsName: null,
        bytesPerPixel: null,
        elevation: null,
      },
      {
        productCode: 'L3M',
        srsName: null,
        bytesPerPixel: null,
        elevation: null,
      },
    ],
  },
  bbp: {
    options: {
      productCode: ['L2'],
      srsName: ['UTM'],
      bytesPerPixel: [2],
      elevation: [false],
    },
    combinations: [
      {
        productCode: 'L2',
        srsName: 'UTM',
        bytesPerPixel: 2,
        elevation: false,
      },
    ],
  },
  stac: {
    options: {
      productCode: ['L1', 'L2', 'L3M', 'L3C'],
      srsName: ['EPSG:4326', 'UTM', 'EPSG:3857'],
      bytesPerPixel: [1, 2],
      elevation: [true, false],
    },
    combinations: [
      {
        productCode: 'L1',
        srsName: 'EPSG:4326', // may by array ['code1', 'code2']
        bytesPerPixel: null,
        elevation: true,
      },
      {
        productCode: 'L2',
        srsName: null,
        bytesPerPixel: null,
        elevation: null,
      },
      {
        productCode: 'L3M',
        srsName: null,
        bytesPerPixel: null,
        elevation: null,
      },
      {
        productCode: 'L3C',
        srsName: null,
        bytesPerPixel: null,
        elevation: null,
      },
    ],
  },
  l1LatLongByte2: {
    options: {
      productCode: ['L1'],
      srsName: ['EPSG:4326'],
      bytesPerPixel: [2],
      elevation: [false],
    },
    combinations: [
      {
        productCode: 'L1',
        srsName: 'EPSG:4326',
        bytesPerPixel: 2,
        elevation: false,
      },
    ],
  },
  l2UTMByte1: {
    options: {
      productCode: ['L2'],
      srsName: ['UTM'],
      bytesPerPixel: [1],
      elevation: [false],
    },
    combinations: [
      {
        productCode: 'L2',
        srsName: 'UTM',
        bytesPerPixel: 1,
        elevation: false,
      },
    ],
  },
  l2UTMByte2: {
    options: {
      productCode: ['L2'],
      srsName: ['UTM'],
      bytesPerPixel: [2],
      elevation: [false],
    },
    combinations: [
      {
        productCode: 'L2',
        srsName: 'UTM',
        bytesPerPixel: 2,
        elevation: false,
      },
    ],
  },
  l2LatLongByte2: {
    options: {
      productCode: ['L2'],
      srsName: ['EPSG:4326'],
      bytesPerPixel: [2],
      elevation: [false],
    },
    combinations: [
      {
        productCode: 'L2',
        srsName: 'EPSG:4326',
        bytesPerPixel: 2,
        elevation: false,
      },
    ],
  },
};

export const getAvailableOptions = (
  source: NonNullable<ImageSource>, // 'etris' | 'bbp', // | null,
  currentParams: { productCode: string; srsName: string; bytesPerPixel: number; elevation: boolean }
) => {
  // if (source === null) {
  //   return null;
  // }
  const { options, combinations } = productOptionsMapperBySource[source];

  // @ts-ignore
  const currentComb = combinations.find(comb => comb.productCode === currentParams.productCode);
  if (!currentComb) {
    return options;
  }

  const result: typeof options = {
    productCode: options.productCode,
    srsName:
      currentComb.srsName === null ? options.srsName : ([] as string[]).concat(currentComb.srsName),
    bytesPerPixel:
      currentComb.bytesPerPixel === null
        ? options.bytesPerPixel
        : ([] as number[]).concat(currentComb.bytesPerPixel),
    elevation:
      currentComb.elevation === null
        ? options.elevation
        : ([] as boolean[]).concat(currentComb.elevation),
  };

  return result;
};

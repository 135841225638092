import { i18nMark } from '@lingui/react';
import { ActionType } from 'deox';
import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakProfile } from 'keycloak-js';
import { eventChannel } from 'redux-saga';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';

import {
  BASE_URL,
  clearTokenApi,
  getGeofenceGeometry,
  getSTS,
  IAM_CONFIG,
  S3_URL,
  setTokenApi,
} from 'api/realAPI';
import { iamActions, User, UserProfile } from 'ducks/authIAM'; // iamProvider, iamConfig,

import GeoportalMap from 'components/map/Map/Map';
import { drawControl, setGeofenceGeometry } from 'ducks/map';
import { notifyActions } from 'ducks/message';
import * as minio from 'minio';
import { captureSagaException, captureSimpleException } from 'modules/monitor/sentryHelper';
import {
  getTokensFromLocalStorage,
  removeTokensFromLocalStorage,
  setTokensToLocalStorage,
} from 'utils/localStorage';
import { Debugger } from 'utils/logging';
const TAG = 'AuthSaga';
const debug = Debugger(TAG);

export const iamConfig: KeycloakConfig = JSON.parse(IAM_CONFIG || '');
export const iamProvider = Keycloak(iamConfig);
export let s3Client: any;

interface IS3Params {
  useSSL: boolean;
  endPoint: string;
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  region: string;
  port?: number;
}

// https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
// TODO - `new Keycloak` in authIAM duck ?
// const iamProvider = new Keycloak();
// iamProvider.init({ token, refreshToken });
const iamInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso', // проверить статус: check-sso, залогиниться обязательно: login-required
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html', // url для тихой проверки в iframe
  checkLoginIframe: false, // нужно ли проверять в iframe актуальность credentials (при входе) по timeout
  // TODO - from LocalStorage { token, refreshToken } and ... updateToken
};

// iam auth window
let currentOpenedChildWindow: Window | null;

export const getPresignedUrl = async (url: string) => {
  const parsedUrl = new URL(url);
  let bucketS3;
  let objectS3;
  if (['http:', 'https:'].includes(parsedUrl.protocol)) {
    bucketS3 = parsedUrl.pathname.split('/')[1];
    objectS3 = parsedUrl.pathname.split('/').slice(2).join('/');
  } else if (parsedUrl.protocol === 's3:') {
    bucketS3 = parsedUrl.pathname.split('/')[3];
    objectS3 = parsedUrl.pathname.split('/').slice(4).join('/');
  }
  return s3Client.presignedGetObject(bucketS3, objectS3, 24 * 60 * 60);
};

// auth start flow
export function* checkFlow() {
  try {
    const keycloak = iamProvider;
    // TODO - может быть надо попробовать обновить токен сначала?
    // yield call([keycloak, 'updateToken'], 30);)
    // debugger;
    const authenticated = yield call([keycloak, 'init'], iamInitOptions);
    // let profile = null;
    if (authenticated) {
      const profile: KeycloakProfile & { groups: string[]; geofence: string } = yield call([
        keycloak,
        'loadUserProfile',
      ]);
      if (profile.id === undefined) {
        profile.id = keycloak.subject;
      }
      // @ts-ignore (нет в интерфейсе kc, как и .idTokenParsed.scope, и profile.attributes)!
      profile.groups = keycloak.idTokenParsed?.groups || []; // ~ .tokenParsed
      // @ts-ignore
      profile.isFree = keycloak.idTokenParsed?.is_free || false;
      //  @ts-ignore
      profile.geofence = keycloak.tokenParsed?.geofence || false;
      const user = new User(profile as UserProfile);

      // set/update kc tokens (keycloak.token, keycloak.refreshToken)
      setTokensToLocalStorage({
        accessToken: keycloak.token!,
        refreshToken: keycloak.refreshToken!,
      });
      setTokenApi(keycloak.token!);
      const s3Endpoint = new URL(S3_URL!);
      // update profile
      // yield put(iamActions.success(profile as UserProfile));
      user.profile.isGeofenced = user.profile.geofence.length > 0;
      yield put(iamActions.success(user));
      try {
        if (user.profile.isGeofenced) {
          const s3Credentials = yield call(getSTS, keycloak.token!);
          let s3Values: IS3Params = {
            useSSL: true,
            endPoint: s3Endpoint.hostname,
            accessKey: s3Credentials.AccessKeyId,
            secretKey: s3Credentials.SecretAccessKey,
            sessionToken: s3Credentials.SessionToken,
            region: 'ext-dc1',
          };
          if (process.env.NODE_ENV === 'development') {
            s3Values = { ...s3Values, useSSL: false, port: 30900 };
          }
          s3Client = new minio.Client(s3Values);
          const geofenceUrl: string = yield call(getPresignedUrl, user.profile.geofence);
          let geofenceGeometry: any;
          try {
            geofenceGeometry = yield call(getGeofenceGeometry, geofenceUrl);
          } catch (error) {
            yield put(
              notifyActions.push({
                timeout: 8000,
                message: i18nMark(
                  'Не удалось получить описание ограничительного района. Пожалуйста повторите попытку позднее!'
                ),
                place: 'bc',
                color: 'alert',
              })
            );
            yield put(iamActions.logout());
            // removeTokensFromLocalStorage();
          }
          const map = GeoportalMap.getInstance();
          if (geofenceGeometry) {
            const geofenceJson = geofenceGeometry.data.features[0];
            yield call([map, 'addGeofenceLayer'], geofenceJson, true);
            yield put(setGeofenceGeometry(geofenceJson));
            yield put(
              notifyActions.push({
                timeout: 8000,
                message: i18nMark(
                  'Для Вашей учетной записи поиск и заказ снимков ограничен районом, заданным Оператором системы!'
                ),
                place: 'bc',
                color: 'info',
              })
            );
            if (geofenceJson.geometry.coordinates.length === 1) {
              yield put(drawControl.setGeometry(geofenceJson));
            } else {
              yield put(
                notifyActions.push({
                  timeout: 8000,
                  message: i18nMark(
                    'Указанная область не может быть использована в качестве района интереса!'
                  ),
                  place: 'bc',
                  color: 'warning',
                })
              );
            }
          }
        }
        // }
      } catch (error) {
        yield put(
          notifyActions.push({
            timeout: 8000,
            message: i18nMark('Ошибка подключения к хранилищу S3! Обновите страницу!'),
            place: 'bc',
            color: 'warning',
          })
        );
        captureSimpleException(error, 'Failed connect to S3 storage');
        yield put(iamActions.logout());
      }
    } else {
      yield put(iamActions.failure(null));
      // костыль, т.к. init не выбрасывает ошибки типа: {"error":"invalid_grant","error_description":"Session not active"} - 400
      removeTokensFromLocalStorage();
    }
    debug.log('checked status', { keycloak, authenticated });
  } catch (error) {
    captureSimpleException(error, 'Check auth Flow');
    debug.error(error);
  }
}
export function* checkWatch() {
  yield takeLatest(iamActions.check, checkFlow);
}

function getMessageChannel() {
  return eventChannel(emitter => {
    const newMessageCallback = (event: any) => {
      if (
        event.origin !== window.location.origin &&
        event.origin !== new URL(iamConfig.url!).origin
      ) {
        // что-то пришло с неизвестного домена. Давайте проигнорируем это
        debug.error('origin в postMessage не совпадает!');
        return;
      }

      if (event.data === 'auth:success') {
        debug.log(`new message: "${event.data}"`);
        emitter(event.data);
      }
    };
    window.addEventListener('message', newMessageCallback, false);
    // return unsubscribe function;
    return window.removeEventListener.bind(window, 'message', newMessageCallback);
  });
}

// main init and watch Flow
function* watchForIAMAuth() {
  // get tokens from storage on page load
  const { accessToken, refreshToken } = getTokensFromLocalStorage();
  if (accessToken && refreshToken) {
    iamInitOptions.token = accessToken;
    iamInitOptions.refreshToken = refreshToken;
  }
  //
  yield put(iamActions.check());

  const channel = yield call(getMessageChannel);
  try {
    while (true) {
      // take(END) will cause the saga to terminate by jumping to the finally block
      const result = yield take(channel);

      if (result === 'auth:success') {
        // const keycloak = iamProvider;
        // yield call([keycloak, 'init'], initOptions);
        yield put(iamActions.check());

        if (currentOpenedChildWindow) {
          currentOpenedChildWindow.close();
        }
      }
    }
  } catch (error) {
    captureSimpleException(error, 'Watch For IAMAuth');
    debug.error(error);
  } finally {
    debug.log('AuthChannel terminated');
  }
}

// login flow
export function* loginFlow(action: ActionType<typeof iamActions.login>) {
  try {
    // const isPopup = action.payload === 'popup' ? true : false;
    const { popup: isPopup, redirectUri } = action.payload || {};
    const keycloak = iamProvider;

    if (isPopup) {
      const loginUrl = yield call([keycloak, 'createLoginUrl'], {
        redirectUri: `${BASE_URL}/auth-ok.html`,
      });
      const childWindow = window.open(
        loginUrl,
        'targetWindow',
        'toolbar=no,location=no,status=no,menubar=no,width=400,height=500'
      );
      currentOpenedChildWindow = childWindow;
    } else {
      yield call([keycloak, 'login'], {
        redirectUri: redirectUri ? `${BASE_URL}${redirectUri}` : `${BASE_URL}`,
      });
    }
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    debug.error(error);
  }
}
export function* loginWatch() {
  yield takeLatest(iamActions.login, loginFlow);
}

// register flow
export function* registerFlow(action: ActionType<typeof iamActions.register>) {
  try {
    const isPopup = action.payload === 'popup' ? true : false;
    const keycloak = iamProvider;

    if (isPopup) {
      const registerUrl = yield call([keycloak, 'createRegisterUrl'], {
        redirectUri: `${BASE_URL}/auth-ok.html`,
      });
      const childWindow = window.open(
        registerUrl,
        'targetWindow',
        'toolbar=no,location=no,status=no,menubar=no,width=400,height=600' // width=400,height=650 or without height
      );
      currentOpenedChildWindow = childWindow;
    } else {
      yield call([keycloak, 'register'], {
        redirectUri: `${BASE_URL}`,
      });
    }
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    debug.error(error);
  }
}
export function* registerWatch() {
  yield takeLatest(iamActions.register, registerFlow);
}

// logout flow
export function* logoutFlow(_action: ActionType<typeof iamActions.logout>) {
  try {
    removeTokensFromLocalStorage();
    clearTokenApi();
    const keycloak = iamProvider;
    yield call([keycloak, 'logout'], {
      redirectUri: `${BASE_URL}`,
    });
  } catch (error) {
    captureSagaException(error, _action.type);
    debug.error(error);
  }
}
export function* logoutWatch() {
  yield takeLatest(iamActions.logout, logoutFlow);
}

export default function* authRoot() {
  yield fork(checkWatch); // authStartFlow
  yield fork(watchForIAMAuth);
  yield fork(loginWatch);
  yield fork(registerWatch);
  yield fork(logoutWatch);
}

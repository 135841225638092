// import { TOTILER_URL } from 'api/realAPI';
import { BandCombinationType, ImageMetadataDetail } from 'ducks/types/metadataTypes';
import WKT from 'ol/format/WKT';

export const getLayersDataFromImageMetadata = (
  imagesMetadata: ImageMetadataDetail[],
  bandCombination: BandCombinationType,
  isPyramid: boolean
) => {
  const result: any[] = [];
  // const images = imagesMetadata.filter(img => img.)
  for (const image of imagesMetadata) {
    const layerData = {
      identifier: `${image.identifier}.${bandCombination}`,
      type: 'XYZ',
      name: `images:${image.identifier}.${bandCombination}`,
      config: {
        options: {
          extent: [] as number[],
          urls: [] as string[],
          visible: true,
          zIndex: 32,
          attributions:
            "© <a style='color: #fff' href='https://www.roscosmos.ru/' target='_blank'>Роскосмос</a>",
          minZoom: isPyramid && bandCombination === 'pan' ? 15 : 1,
          maxZoom: isPyramid && bandCombination === 'ms' ? 16 : 20,
        },
      },
    };
    image.coverage
      ?.filter(img => img.bandCombination === bandCombination)
      .forEach(layer => {
        if (layer.resourceUrls.tms !== undefined) {
          layerData.config.options.urls
            // .push(`${VTMS_URL}/{z}/{x}/{y}?url=${layer.resourceUrls.s3}${bandCombination === 'pan' ? '&nodata=0' : ''}`)
            // .push(`${VTMS_URL}/{z}/{x}/{y}?url=${layer.resourceUrls.s3}`);
            .push(layer.resourceUrls.tms);
        }
      });
    const imageGeometry = new WKT().readFeature(image.geometry, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    layerData.config.options.extent = imageGeometry.getGeometry()?.getExtent()!;
    result.push(layerData);
  }
  return result;
};

import { Trans } from '@lingui/macro';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from 'components/utils/date';
import {
  CartRequest,
  fetchCartOrder,
  fetchCartRequests,
  getCartRequests,
  getIsFetchingCartRequests,
  getPagination,
  getSortingCartRequests,
  getTotalCartRequests,
  sortCartRequests,
} from 'ducks/order';
import RequestDetailPanel from './RequestDetailPanel';
import RequestProductsPanel from './RequestProductsPanel';

// import IconButton from '@geobank/components/src/common/IconButton/IconButton';
// import BurgerMenuIcon from '@geobank/assets/src/icons/BurgerMenuIcon';

// import SortableTable from 'packages/components/Table/SortableTable';
// import TablePagination from 'packages/components/TablePagination/TablePagination';
import SortableTable from '@geobank/components/src/common/Table/SortableTable';
import TablePagination from '@geobank/components/src/common/TablePagination/TablePagination';
import { formatCurrency } from 'components/utils/format';
import { getUser, User } from 'ducks/authIAM';
import { statuses } from 'locales/dicts/requestStatus';

import ChipTag from 'components/order/ChipTag/ChipTag';
import SelectTag from 'components/order/SelectTag/SelectTag';

import GeoportalMap, { LAYER_GROUPS } from 'components/map/Map/Map';
import BaseLayer from 'ol/layer/Base';
import styles from './RequestsWithSortTab.module.scss';

// headCells, tableSchema, columnsSchema
const getTableColumns = ({ user }: { user: User }) => [
  /* {
    id: 'id',
    label: '№',
    sortable: true,
    // valueField: 'id' | null,
    // renderValue: (item: any, index: number) => index,
    // compareFunc: (a: any, b: any) => a.id - b.id,
  }, */
  {
    id: 'number',
    label: <Trans>Номер</Trans>,
    sortable: false,
    // innerProps: {
    //   style: { maxWidth: '10rem' },
    // },
    dataCellProps: {
      style: { maxWidth: '10rem' },
    },
  },
  {
    id: 'createdAt',
    label: <Trans>Дата</Trans>,
    sortable: true,
    renderValue: (record: CartRequest) => formatDate(new Date(record.createdAt), 'dd.MM.yyyy'),
    // compareFunc: (a: any, b: any) => a.id - b.id,
  },
  {
    id: 'totalPrice',
    label: <Trans>Цена</Trans>,
    sortable: false,
    renderValue: (record: CartRequest) => formatCurrency(record.totalPrice), // `${record.totalPrice} р`,
  },
  {
    id: 'status',
    label: <Trans>Статус</Trans>,
    sortable: true,
    renderValue: (record: CartRequest) => {
      const lastStatus =
        (get(record, 'statuses[0].status') as CartRequest['statuses'][0]['status']) || undefined;
      let needRetryPay = false;
      if (lastStatus === 'payment_await') {
        needRetryPay = true;
      }

      return (
        <>
          <Trans id={statuses[lastStatus || '-']} />
          {needRetryPay && record.paymentParams?.formUrl && user.haveToPay() && (
            <>
              <br />
              <a href={record.paymentParams.formUrl} target="_blank" rel="noopener noreferrer">
                <Trans>Оплатить</Trans>
              </a>
            </>
          )}
        </>
      );
    }, // status.status
  },
  // {
  //   id: 'actions',
  //   label: '',
  //   sortable: false,
  //   dataCellProps: {
  //     className: 'noVPaddings',
  //   },
  //   renderValue: () => (
  //     <>
  //       <div
  //         // className={styles.dataCell}
  //         onClick={e => {
  //           e.stopPropagation();
  //           console.log('context menu');
  //         }}
  //       >
  //         {/* ||| */}
  //         <IconButton>
  //           <BurgerMenuIcon />
  //         </IconButton>
  //       </div>
  //     </>
  //   ),
  // },
];

// function compareNumbers(a: any, b: any) {
//   return (a.id - b.id) * -1;
// }

// const getSortFunc = (field: string, direction = 'ASC') => {
//   const fieldData = headCells.find(item => item.id === field);
//   let sortFunc = fieldData?.compareFunc ? fieldData.compareFunc : undefined;
//   if (sortFunc !== undefined && direction !== 'ASC') {
//     sortFunc = (a, b) => sortFunc!(a, b) * -1;
//   }
//   return sortFunc;
// };

// const PAGE_SIZE = 10;

export const clearPreviewProduct = () => {
  const map = GeoportalMap.getInstance();
  const userLayers: BaseLayer | undefined = map
    .getOlMap()
    .getLayers()
    .getArray()
    .find(layer => layer.get('name') === LAYER_GROUPS.userLayers);
  const productPreviewLayers = userLayers?.getLayersArray();
  if (productPreviewLayers && productPreviewLayers.length > 0) {
    productPreviewLayers?.map(layer => {
      if (
        layer.get('name').startsWith('productPreview') ||
        layer.get('name').startsWith('productContour')
      ) {
        const layerName = layer.get('name');
        map.removeLayerByKey(layerName, 'name', LAYER_GROUPS.userLayers);
      }
    });
  }
};

const captionRender = (currentPage: number, rowsPerPage: number, totalCount: number) => {
  return (
    <Trans>
      {(currentPage - 1) * rowsPerPage + 1}-{currentPage * rowsPerPage} из {totalCount}
    </Trans>
  );
};

const RequestsWithSortTab: React.FC = () => {
  const [activeRequest, setActiveRequest] = React.useState<CartRequest | null>(null); // as null;
  const [filterTags, setFilterTags] = React.useState<string[]>([]);
  const cartRequests = useSelector(getCartRequests);
  const totalCount = useSelector(getTotalCartRequests);
  const isFetching = useSelector(getIsFetchingCartRequests);
  const sorting = useSelector(getSortingCartRequests);
  const { currentPage, pageSize } = useSelector(getPagination);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const handleSort = (field: string, direction: 'ASC' | 'DESC' = 'ASC') => {
    dispatch(sortCartRequests(field, direction));
  };

  // const records = sortBy(cartRequests, o => o.id).reverse();
  // const records = sortBy(cartRequests, 'id');
  // const records = cartRequests.sort(compareNumbers);

  React.useEffect(() => {
    // if (cartRequests.length === 0) {
    dispatch(
      fetchCartRequests.request({
        tags: filterTags,
        offset: Math.max(0, pageSize * (currentPage - 1)),
        limit: pageSize,
      })
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    clearPreviewProduct();
  }, [activeRequest]);

  React.useEffect(() => {
    if (activeRequest && activeRequest.products === undefined) {
      dispatch(
        fetchCartOrder.request(activeRequest.number, {}, (req: CartRequest) => {
          // setActiveRequest({ ...activeRequest, ...req });
          const activeOrderUpdated = cartRequests.find(r => r.id === activeRequest.id);
          setActiveRequest(activeOrderUpdated || null);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRequest]);

  const handlePageChange = (newPage: number) => {
    dispatch(
      fetchCartRequests.request({
        tags: filterTags,
        offset: Math.max(0, pageSize * (newPage - 1)), // newPage * pageSize,
        limit: pageSize,
      })
    );
  };

  const handleClose = () => {
    setActiveRequest(null);
  };

  const handleSelect = (record: any) => {
    setActiveRequest(record);
  };

  const addTagFilter = (value: string) => {
    setActiveRequest(null);
    const tagIndex = filterTags.indexOf(value);
    if (tagIndex === -1) {
      const tags = [...filterTags, value];
      setFilterTags(tags);
      dispatch(
        fetchCartRequests.request({
          tags,
          offset: 0,
          limit: pageSize,
        })
      );
    }
  };

  const removeTagFilter = (value: string) => {
    const tags = [...filterTags];
    const tagIndex = tags.indexOf(value);
    if (tagIndex > -1) {
      tags.splice(tagIndex, 1);
      setFilterTags(tags);
      dispatch(
        fetchCartRequests.request({
          tags,
          offset: 0,
          limit: pageSize,
        })
      );
    }
  };

  const getContent = () => {
    if (cartRequests.length === 0) {
      return (
        <div className={styles.emptyMessage}>
          <span>
            {isFetching ? (
              <Trans>Загрузка списка заявок ...</Trans>
            ) : (
              <Trans>У Вас нет ни одной созданной заявки</Trans>
            )}
          </span>
        </div>
      );
    } else {
      return (
        <SortableTable
          className={styles.table}
          columns={getTableColumns({ user })}
          records={cartRequests}
          sorting={sorting}
          // @ts-ignore
          activeRecordIds={activeRequest ? [activeRequest.id] : []}
          onRequestSort={handleSort}
          onRowSelect={handleSelect}
        />
      );
    }
  };

  return (
    // <div className={styles.qqq}>
    <div className={styles.root}>
      <div className={styles.mainBlockContainer}>
        {/* <h3 className={styles.title}>
          <Trans>Актуальные заявки</Trans>
        </h3> */}
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>
            <Trans>Актуальные заявки</Trans>
          </h3>
          <SelectTag onSelect={val => addTagFilter(val)} />
        </div>
        <div style={{ margin: '0 20px' }}>
          {filterTags.length > 0 && (
            <span>
              <Trans>Фильтры</Trans>:
            </span>
          )}
          {filterTags.map(tagName => (
            // <span key={tagName} onClick={() => removeTagFilter(tagName)}>
            //   {tagName}
            // </span>
            <ChipTag
              key={tagName}
              value={tagName}
              title={tagName.length > 15 ? tagName : undefined}
              onDelete={val => val && removeTagFilter(val)}
            >
              {tagName}
            </ChipTag>
          ))}
        </div>
        {/* --- */}
        {getContent()}
        {/* --- */}
        {pageSize < totalCount && (
          <TablePagination
            count={totalCount}
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handlePageChange}
            captionRender={captionRender}
          />
        )}
      </div>
      {activeRequest !== null && (
        <div className={styles.secondBlockContainer}>
          <div className={styles.secondBlockInner}>
            <RequestDetailPanel
              request={activeRequest}
              onClose={handleClose}
              onTagClick={addTagFilter}
              actions={['close']}
            />
          </div>
          {activeRequest.products && (
            <div className={styles.secondBlockInner}>
              <RequestProductsPanel
                products={activeRequest.products}
                images={activeRequest.images}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestsWithSortTab;

export const cutFileIdentifier = (identifier: string, start: number = 0, count: number = 8) => {
  return identifier.split('.').slice(start, count).join('.');
};

export const cutIdentifierToInput = (identifier: string, start: number = 0, count: number = 8) => {
  return `${identifier.split('').slice(start, count).join('')}...`;
};

export const apoiRouteIdentifier = (identifier: string) => {
  return identifier.split('.').slice(0, -1).join('.');
};
// emptyStub/emptyChar
export const formatValue = (value: any, measure: string = '', emptyStub: string = '-') => {
  // {item.cloudiness || '-'} %
  let result = emptyStub;
  if (value !== undefined && value !== null) {
    result = `${value} ${measure}`.trim();
  }
  return result;
};

export function bytesToHumanReadableSize(bytes: number): string {
  const sizes = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб'];
  if (bytes === 0) return '0 Байт';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

export function bytesToHumanReadableSizeSplited(bytes: number): [number, string] {
  const sizes = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб'];
  // if (bytes === 0) return '0 Байт';
  if (bytes === 0) return [0, sizes[0]];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return [Math.round(bytes / Math.pow(1024, i)), sizes[i]];
}

// https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export function formatCurrency(value: number, options = {}) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    // maximumFractionDigits: 2,
    ...options,
  });
  return formatter.format(value);
}

export function formatNumber(value: number, options = {}) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    // minimumFractionDigits: 0,
    ...options,
  });
  return formatter.format(value);
}

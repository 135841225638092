import React from 'react';

const EyeIconRGB: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" version="1.1" id="svg6">
      <defs id="defs10" />
      <path
        opacity="0.3"
        d="m 0.65365359,9.8817624 c 0,0 2.56965401,-6.2813899 9.42208641,-6.2813899 5.139309,0 9.422086,6.2813899 9.422086,6.2813899 0,0 -4.282777,6.2813906 -9.422086,6.2813906 -6.8524324,0 -9.42208641,-6.2813906 -9.42208641,-6.2813906 z"
        fill="#5dbdf9"
        fillOpacity="1"
        strokeWidth="1.44928"
        id="path2480"
      />
      <g id="g3440" transform="matrix(1.6873817,0,0,1.7387936,-7.1247425,-7.9138717)">
        <path
          id="path2482"
          fill="#ed333b"
          fillOpacity="0.85"
          strokeWidth="1.15363"
          d="m 10.211172,7.7344762 c -0.0059,-1.84e-5 -0.0117,0 -0.01758,0 -1.380706,0 -2.5,1.1192928 -2.5,2.4999998 0,0.696366 0.28476,1.325985 0.744141,1.779297 l 1.773437,-1.773437 z"
        />
        <path
          id="path3434"
          fill="#1a5fb4"
          fillOpacity="0.85"
          strokeWidth="1.15363"
          d="m 10.211172,7.7344762 v 2.5058598 l -1.773437,1.773437 c 0.451442,0.445479 1.071523,0.720703 1.755859,0.720703 0.692309,0 1.318772,-0.28165 1.771485,-0.736328 l -1.75,-1.75 V 7.7344762 c -0.0013,-1.1e-5 -0.0026,4.1e-6 -0.0039,0 z"
        />
        <path
          id="path3431"
          fill="#33d17a"
          fillOpacity="0.85"
          strokeWidth="1.15363"
          d="m 10.215079,7.7344762 v 2.5136718 l 1.75,1.75 c 0.450149,-0.452104 0.728515,-1.075282 0.728515,-1.763672 0,-1.3735238 -1.107731,-2.488435 -2.478515,-2.4999998 z"
        />
      </g>
    </svg>
  );
};

export default EyeIconRGB;

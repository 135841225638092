import { ActionType } from 'deox'; // createActionCreator, createReducer
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchLayers, getBaseLayers, getTopLayers } from 'ducks/mapLayers';

import API from 'api';
import GeoportalMap from 'components/map/Map/Map';
import { getLanguage } from 'ducks/locale';

export function* fetchMapLayersFlow(_action: ActionType<typeof fetchLayers.request>) {
  try {
    const data = yield call(API.getMapLayers);
    const locale: ReturnType<typeof getLanguage> = yield select(getLanguage);
    yield put(fetchLayers.success(data, locale));
  } catch (error) {
    yield put(fetchLayers.failure(error));
  }
}

export function* fetchMapLayersSuccessFlow() {
  const baseLayers: ReturnType<typeof getBaseLayers> = yield select(getBaseLayers);
  const topLayers: ReturnType<typeof getTopLayers> = yield select(getTopLayers);
  try {
    const map = GeoportalMap.getInstance();
    yield call([map, 'setBaseLayers'], baseLayers);
    yield call([map, 'toggleBaseLayer'], 'Yandex');
    yield call([map, 'toggleTopLayer'], topLayers.find(layer => layer.id === 'YandexHybrid')!);
  } catch (error) {
    console.error('Error', error);
  }
}

export default function* mapLayersRoot() {
  yield all([takeLatest(fetchLayers.request, fetchMapLayersFlow)]);
  yield all([takeLatest(fetchLayers.success, fetchMapLayersSuccessFlow)]);
}

import { Trans } from '@lingui/macro';

import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip-lite';

import CheckBoxTree, {
  CheckBoxTreeInterface,
  CheckboxTreeNode,
} from '@geobank/components/src/common/CheckBoxTree/CheckBoxTree';
// import CheckBoxTree, { CheckBoxTreeInterface } from 'components/CheckBoxTree/CheckBoxTree';

import * as store from 'ducks';
import { getIsFree } from 'ducks/authIAM';
import { fetchPlatforms, getInstrumentsById, getPlatformTypes } from 'ducks/classifier';
import { Instrument, PlatformType } from 'ducks/types/classifierTypes';
import { SourceType } from 'ducks/types/metadataTypes';
import { instrumentDescriptions, instruments, platformTypes } from 'locales/dicts/classifiers'; // './translations';
import { connect } from 'react-redux';

const styles = require('./PlatformTree-styles.module.scss');

export const getPlatformIds = (
  instrumentsData: { [key: string]: Instrument },
  instrumentsIds: string[]
) => {
  const platforms = Object.entries(instrumentsData).reduce((acc: string[], [key, value]) => {
    if (instrumentsIds.includes(key)) {
      if (!acc.includes(value.platformType)) {
        acc.push(value.platformType);
      }
    }
    return acc;
  }, []);
  return platforms;
};

interface PlatformTreeProps {
  onChange?: (data: any, changed: { name: string; value: boolean }) => void;
  // platformTypesData: any;
  platformTypesData: PlatformType[];
  instrumentsData: { [key: string]: Instrument };
  fetchPlatformsRequest: typeof fetchPlatforms.request;

  checked?: string[];

  source: SourceType;
  isFreeUser: boolean;
}

interface PlatformTreeState {
  // selected!
  platformIds: Array<string | undefined>;
  sensorTypeIds: string[]; // number[];
}

class PlatformTree extends PureComponent<PlatformTreeProps, PlatformTreeState> {
  public state: PlatformTreeState = {
    platformIds: [],
    sensorTypeIds: [],
  };

  public componentDidMount() {
    if (this.props.platformTypesData.length === 0) {
      this.props.fetchPlatformsRequest();
    }

    if (this.props.checked !== undefined) {
      this.setState({
        platformIds: getPlatformIds(this.props.instrumentsData, this.props.checked),
        sensorTypeIds: this.props.checked,
      });
    }
  }

  public componentDidUpdate(prevProps: PlatformTreeProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({
        platformIds: getPlatformIds(this.props.instrumentsData, this.props.checked!),
        sensorTypeIds: this.props.checked || [],
      });
    }
  }

  // checkedCheckboxTreeObject: any, checked: number[]
  private handleChange = (
    checkedTreeObjects: any,
    checked: string[],
    targetNode: CheckboxTreeNode
  ) => {
    const sensorIds: string[] = [];
    const platformIds: string[] = [];
    for (const checkedObj of checkedTreeObjects) {
      const segmentsInstr = checkedObj.value.split(':');
      const segmentsPlatform = checkedObj.parent.value.split(':');

      // sensorIds.push(Number(segments[1]));
      sensorIds.push(segmentsInstr[1]);
      if (!platformIds.includes(segmentsPlatform[1])) {
        platformIds.push(segmentsPlatform[1]);
      }
    }
    this.setState(
      { sensorTypeIds: sensorIds },
      () =>
        this.props.onChange &&
        this.props.onChange(
          { sensorTypeIds: sensorIds, platformTypeIds: platformIds },
          { name: targetNode.value, value: targetNode.checked }
        )
    );
  };

  private renderPlatformTypeSensors = (data: any): CheckBoxTreeInterface[] => {
    const sensorTypes: CheckBoxTreeInterface[] = [];
    for (const sensorData of data) {
      sensorTypes.push({
        type: 'sensorType',
        value: `sensorType:${sensorData.id}`,
        label: (
          <span>
            {sensorData.instrument} ({sensorData.sensorName})
          </span>
        ),
        // children: sensorData.sensors ? renderPlatformTypeSensors(sensorData.sensors) : null,
      });
    }
    return sensorTypes;
  };

  private renderPlatformTypeInstruments = (data: any): CheckBoxTreeInterface[] => {
    const sensorTypes: CheckBoxTreeInterface[] = [];
    const instrumentIds = [];
    for (const sensorData of data) {
      if (instrumentIds.indexOf(sensorData.instrumentIdentifier) > -1) {
        continue;
      }
      const sensorType = {
        type: 'sensorType',
        value: `sensorType:${sensorData.instrumentIdentifier}`,
        // label: <span>{sensorData.instrumentName}</span>,
        label: (
          <Tooltip
            tagName="span"
            tipContentClassName="tip-content--border-grey tip-content--instrumentdescription"
            distance={18} // 10 without arrow; 18 - with
            hoverDelay={1400}
            arrow={true}
            background="white"
            color="black"
            direction="right"
            // @ts-ignore
            content={<Trans id={instrumentDescriptions[sensorData.instrumentIdentifier]} />}
          >
            <Trans id={instruments[sensorData.instrumentIdentifier]} />
          </Tooltip>
        ),
      };
      if (['GSA', 'SVR', 'KMSS'].includes(sensorData.instrumentIdentifier)) {
        if (this.props.isFreeUser) {
          sensorTypes.push(sensorType);
        }
      } else {
        sensorTypes.push(sensorType);
      }
      instrumentIds.push(sensorData.instrumentIdentifier);
    }
    return sensorTypes;
  };

  // const renderPlatformTypeNode = (type, value, title) => {
  private renderPlatformTypeNode = (data: any): CheckBoxTreeInterface => {
    return {
      type: 'platformType',
      value: `platformType:${data.identifier}`, // data.id
      // label: <span>{data.name}</span>, // data.identifier
      label: <Trans id={platformTypes[data.identifier]} />,
      // children: data.sensors ? this.renderPlatformTypeSensors(data.sensors) : undefined,
      children: data.sensors ? this.renderPlatformTypeInstruments(data.sensors) : undefined,
    };
  };

  public render() {
    const { platformTypesData /* source*/ } = this.props;
    const { sensorTypeIds } = this.state;
    const pltNodes: CheckBoxTreeInterface[] = [];
    for (const plt of platformTypesData) {
      // if (source === 'survey' && !['KV', 'RP'].includes(plt.identifier)) {
      // } else {
      if (['AI', 'SZ2M', 'MM', 'ZY', 'GF', 'CBERS', 'IRSR'].includes(plt.identifier)) {
        if (this.props.isFreeUser) {
          pltNodes.push(this.renderPlatformTypeNode(plt));
        }
      } else if (!['CNDR', 'EL', 'ARCM'].includes(plt.identifier)) {
        pltNodes.push(this.renderPlatformTypeNode(plt));
      }
      // }
    }
    const checked = sensorTypeIds.map(id => `sensorType:${id}`);

    return (
      <div className={styles.root}>
        <CheckBoxTree nodes={pltNodes} checked={checked} onChange={this.handleChange} />
      </div>
    );
  }
}

export default connect(
  // ({ metadata }: store.RootState) => ({ metadata }),
  (state: store.RootState) => ({
    platformTypesData: getPlatformTypes(state), // platformTypes
    instrumentsData: getInstrumentsById(state),
    isFreeUser: getIsFree(state),
  }),
  {
    fetchPlatformsRequest: fetchPlatforms.request,
  }
)(PlatformTree);

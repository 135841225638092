// renamed FooterActions -> SearchResultItemFooter
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React from 'react';
import Tooltip from 'react-tooltip-lite';

import ContourIcon from '@geobank/assets/src/icons/ContourIcon';
import FileDownloadIcon from '@geobank/assets/src/icons/FileDownloadIcon';
// import EyeIcon from '@geobank/assets/src/icons/EyeIcon';
import PictureIcon from '@geobank/assets/src/icons/PictureIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
// import { ImageMetadataDetail } from 'ducks/types/metadataTypes';
import Checkbox, { CHECKED, INDETERMINATE, UNCHECKED } from './SearchResultItem/CheckBox/Checkbox';

import styles from './SearchResultHeaderActions.module.scss';

const tooltipProps = {
  tipContentClassName: 'tip-content--border-grey', // tip-content--searchheaderactions
  arrow: false,
  direction: 'up',
  hoverDelay: 800,
  padding: '8px',
  background: 'white',
  color: 'black',
};

interface SearchResultHeaderActionsProps {
  checkValue: typeof CHECKED | typeof UNCHECKED | typeof INDETERMINATE;
  onCheckAll: (value: boolean) => void;
  // actions: {
  onToggleContour: () => void; // (item: ImageMetadataDetail) => void;
  onTogglePreview: () => void;
  onToggleFullImage: () => void;
  onMetadataDownload: () => void;
  // };
}

const SearchResultHeaderActions: React.FC<SearchResultHeaderActionsProps> = props => {
  const { checkValue } = props;

  const handleCheckAll = (name: string, checked: boolean) => {
    props.onCheckAll(checked);
  };

  const handleToggleContour = () => {
    props.onToggleContour();
  };

  const handleTogglePreview = () => {
    props.onTogglePreview();
  };

  // const handleToggleFullImage = () => {
  //   props.onToggleFullImage();
  // };

  const handleMetadataDownload = () => {
    props.onMetadataDownload();
  };

  return (
    <div className={styles.listItemFooter}>
      <div>
        <div className={styles.test11} style={{ marginRight: '10px' }}>
          <Tooltip
            {...tooltipProps}
            direction="up-start" // right
            distance={8}
            tipContentClassName="tip-content--border-grey tip-content--width-150"
            content={
              checkValue === CHECKED ? (
                <Trans id="image_group_actions.uncheck_all">Очистить выбор</Trans> // Очистить выбор со всех снимков
              ) : (
                <Trans id="image_group_actions.check_all">Выбрать все снимки</Trans>
              )
            }
          >
            <Checkbox name="all:777" value={checkValue} onChange={handleCheckAll} />
          </Tooltip>
        </div>

        <div className={styles.test11}>
          <Trans>Карта:</Trans>
        </div>
        <div className={styles.test11}>
          <Tooltip
            {...tooltipProps}
            // content={i18n._(t`Показать/скрыть контура (выбранных снимков)`)}
            content={
              <Trans id="image_group_actions.contours">
                Показать/скрыть контура <br /> (выбранных снимков)
              </Trans>
            }
          >
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton, {
                // active: props.isContourActive,
              })}
              // title="Контур"
              onClick={handleToggleContour}
            >
              <ContourIcon fill="currentColor" />
            </Button>
          </Tooltip>
        </div>
        <div className={styles.test11}>
          <Tooltip
            {...tooltipProps}
            // content={i18n._(t`Показать/скрыть обзорные изображения (выбранных снимков)`)}
            content={
              <Trans id="image_group_actions.preview_images">
                Показать/скрыть обзорные изображения <br /> (выбранных снимков)
              </Trans>
            }
          >
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton, {
                // active: props.isPreviewActive,
              })}
              // title="Обзорное изображение"
              onClick={handleTogglePreview}
            >
              <PictureIcon fill="currentColor" />
            </Button>
          </Tooltip>
        </div>
        {/* {data.coverage.length > 0 && ( */}
        {/* <div className={styles.test11}>
          <Tooltip
            {...tooltipProps}
            // content={i18n._(t`Показать/скрыть снимки в полном разрешении (выбранных снимков)`)}
            // content={<Trans>Показать/скрыть снимки в полном разрешении <br /> (выбранных снимков)</Trans>}
            content={
              <Trans id="image_group_actions.full_resolution_images">
                Показать/скрыть снимки в полном разрешении <br /> (выбранных снимков)
              </Trans>
            }
          >
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton, {
                // active: props.isFullImageActive,
              })}
              // title="Снимок в полном разрешении"
              onClick={handleToggleFullImage}
              // disabled={data.coverage.length === 0}
            >
              <EyeIcon fill="currentColor" />
            </Button>
          </Tooltip>
        </div> */}
        {/* )} */}
      </div>
      <div>
        <div className={styles.test11}>
          <Trans>Метаданные:</Trans>
        </div>

        <div className={styles.test11}>
          <Tooltip
            {...tooltipProps}
            direction="up-end"
            content={
              <Trans id="image_group_actions.metadata_download">
                Скачать метаданные <br /> (выбранных снимков)
              </Trans>
            }
          >
            <Button
              color={ButtonColor.TRANSPARENT}
              className={classNames(styles.mapActionButton)}
              // title="Скачать метаданные"
              onClick={handleMetadataDownload}
            >
              <FileDownloadIcon />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SearchResultHeaderActions;

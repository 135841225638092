import React from 'react';

// import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import SearchIcon from '@geobank/assets/src/icons/SearchIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import { t, Trans } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import AuthButtonIAM from 'components/map/AuthButton/AuthButtonIAM';
import LangSelector from 'components/map/LangSelector/LangSelector';
import MainMenu from 'components/map/MainMenu/MainMenu';
import GeoportalMap from 'components/map/Map/Map';
import UserMenu from 'components/map/UserMenu/UserMenu';
import { getIsAuthenticated } from 'ducks/authIAM';
import { Message, notifyActions } from 'ducks/message';
import { transform } from 'ol/proj';
import { FaExchangeAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import ExpandIcon from '@geobank/assets/src/icons/ExpandIcon';
import classNames from 'classnames';
import { cutIdentifierToInput } from 'components/utils/format';
import { getIsMainPanelShow, mainPanelToggle } from 'ducks/map';
import { searchObjects } from 'ducks/mapObjects';
import styles from './CoordinateSearchInput.module.scss';

const invalidCoordinatesInputMessage: Message = {
  message: (
    <span>
      <Trans>Проверьте правильность ввода координат</Trans>
    </span>
  ),
  color: 'alert',
  timeout: 4000,
};

const CoordinateSearchInput: React.FC<withI18nProps> = props => {
  const { i18n } = props;
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isMainPanelShow = useSelector(getIsMainPanelShow);

  const coordinateInputRef = React.useRef(null);

  const [coordinate, setCoordinate] = React.useState<string>('');

  const handleMainPanelToggle = () => {
    dispatch(mainPanelToggle());
  };

  const handleCoordinateInputChangeClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCoordinate(value);
  };

  const handleButtonEnterKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // const handleClearClick = () => {
  //   setCoordinate('');
  // };

  const handleSearchIdentifierRequest = () => {
    dispatch(searchObjects.request(coordinate));
  };

  const handleInputEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (document.activeElement === coordinateInputRef.current && event.key === 'Enter') {
      handleMoveToCoordinates();
    }
  };

  const handleExchageCoordinatePlace = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const parsedCoordinates = coordinate
      .split(coordinate.includes(',') ? ',' : ' ')
      .map(coord => Number(coord.replace(/\s/g, '')));
    if (parsedCoordinates.every(coord => !isNaN(coord)) && parsedCoordinates.length === 2) {
      setCoordinate(
        [parsedCoordinates[1], parsedCoordinates[0]].join(coordinate.includes(',') ? ', ' : ' ')
      );
    } else {
      dispatch(notifyActions.push(invalidCoordinatesInputMessage));
    }
  };

  const handleMoveToCoordinates = () => {
    if (coordinate.length > 0) {
      const parsedCoordinates = coordinate
        .split(coordinate.includes(',') ? ',' : ' ')
        .map(coord => Number(coord.replace(/\s/g, '')));
      if (parsedCoordinates.every(coord => !isNaN(coord)) && parsedCoordinates.length === 2) {
        const map = GeoportalMap.getInstance().getOlMap();
        const transformedCoordinate = transform(
          [parsedCoordinates[1], parsedCoordinates[0]],
          'EPSG:4326',
          'EPSG:3857'
        );
        map.getView().setCenter(transformedCoordinate);
        map.getView().setZoom(12);
      } else {
        dispatch(notifyActions.push(invalidCoordinatesInputMessage));
      }
    } else {
      dispatch(
        notifyActions.push({
          message: (
            <span>
              <Trans>Не указаны координаты для поиска</Trans>
            </span>
          ),
          color: 'alert',
          timeout: 4000,
        })
      );
    }
  };

  return (
    <div>
      <div className={styles.mapLeftSection}>
        <input
          ref={coordinateInputRef}
          className={styles.inputCoordinates}
          placeholder={i18n._(t`широта, долгота (например, "55.75, 37.6")`)}
          // title={'Введите координаты на карте в десятичном формате (Long, Lat)'}
          type="text"
          onChange={handleCoordinateInputChangeClick}
          onKeyPress={handleInputEnterKeyPress}
          value={coordinate.length > 40 ? `${cutIdentifierToInput(coordinate, 0, 40)}` : coordinate}
        />
        <div className={styles.actionButtonGroup}>
          <Button
            className={styles.actionButton}
            color={ButtonColor.TRANSPARENT}
            title={i18n._(t`Поменять координаты местами`)}
            onClick={handleExchageCoordinatePlace}
            onKeyPress={handleButtonEnterKeyPress}
            disabled={['ETRIS', 'APOI', 'BBP'].includes(coordinate.split('.')[0])}
          >
            <FaExchangeAlt size={'20px'} color={'#5dbdf9'} strokeWidth={'1px'} />
          </Button>
          {/* <Button
            className={styles.actionButton}
            color={ButtonColor.TRANSPARENT}
            title={'Очистить координаты'}
            onClick={handleClearClick}
          >
            <CrossIcon fill="grey" />
          </Button> */}
          <Button
            className={styles.actionButton}
            color={ButtonColor.TRANSPARENT}
            title={i18n._(t`Искать координаты на карте`)}
            onClick={
              ['ETRIS', 'APOI', 'BBP'].includes(coordinate.split('.')[0])
                ? handleSearchIdentifierRequest
                : handleMoveToCoordinates
            }
          >
            <SearchIcon />
          </Button>
        </div>
        <div className={styles.closePanelWrap}>
          <span
            title={
              isMainPanelShow
                ? i18n._(t`Закрыть основную панель`)
                : i18n._(t`Открыть основную панель`)
            }
            className={classNames(styles.closePanelBtn, {
              [styles.closePanelBtnShow]: isMainPanelShow,
            })}
            onClick={handleMainPanelToggle}
          >
            <ExpandIcon />
          </span>
        </div>
      </div>
      {isAuthenticated ? (
        <div className={styles.mapMainGroup}>
          <LangSelector className={styles.marginLeft} />
          <UserMenu className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      ) : (
        <div className={styles.mapMainGroup}>
          <LangSelector className={styles.marginLeft} />
          <AuthButtonIAM className={styles.marginLeft} />
          <MainMenu className={styles.marginLeft} />
        </div>
      )}
    </div>
  );
};

export default withI18n()(CoordinateSearchInput);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';

import LayersIcon from '@geobank/assets/src/icons/LayersIcon';
import Dropdown from '@geobank/components/src/common/Dropdown/Dropdown';

import {
  addTopLayer,
  changeTopLayerUrl,
  getBaseLayers,
  getSelectedBaseLayerId,
  getSelectedMosaicLayerId,
  getSelectedTopLayerId,
  getTopLayers,
  removeTopLayer,
  toggleBaseLayer,
  toggleTopLayer,
} from 'ducks/mapLayers';
import { mosaicLayerIds } from '../Map/Map';
import STACItemsLayer from '../STACItems/STACItemsLayer';
import MosaicLayerControl from './MosaicLayerControl/MosaicLayerControl';

import { getLanguage } from 'ducks/locale';

import styles from './BaseLayerControl.module.scss';

const BaseLayerControl: React.FC<withI18nProps> = props => {
  const { i18n } = props;
  const dispatch = useDispatch();
  const locale = useSelector(getLanguage);
  const baseLayers = useSelector(getBaseLayers);
  const topLayers = useSelector(getTopLayers);
  const selectedBaseLayerId = useSelector(getSelectedBaseLayerId);
  const selectedMosaicLayerId = useSelector(getSelectedMosaicLayerId);
  const selectedTopLayerIds = useSelector(getSelectedTopLayerId);

  React.useEffect(() => {
    selectedBaseLayerId === 'Yandex'
      ? dispatch(addTopLayer('YandexHybrid'))
      : dispatch(removeTopLayer('YandexHybrid'));
  }, [dispatch, selectedBaseLayerId]);

  React.useEffect(() => {
    if (baseLayers.length > 0) {
      dispatch(changeTopLayerUrl(locale));
    }
  }, [dispatch, locale, baseLayers]);

  const handleBaseLayerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const layerName: string = event.target.id;
    dispatch(toggleBaseLayer(layerName));
  };

  const handleTopLayerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const layerName: string = event.target.id;
    dispatch(toggleTopLayer(layerName));
  };

  const baseLayerItems = baseLayers.map(item => (
    <Dropdown.MenuItem key={item.id}>
      <span key={item.id}>
        <input
          id={item.id}
          type="radio"
          name="layer"
          value={item.id}
          onChange={handleBaseLayerChange}
          readOnly
          checked={selectedBaseLayerId === item.id}
        />
        <label className={styles.inputLabel} htmlFor={item.id}>
          {i18n.language === 'en' ? item.name_en : item.name}
        </label>
      </span>
    </Dropdown.MenuItem>
  ));

  const topLayerItems = topLayers.map((item: any) => (
    <Dropdown.MenuItem key={item.id}>
      <span key={item.id}>
        <input
          className={styles.checkbox}
          type="checkbox"
          id={item.id}
          name={item.id}
          checked={selectedTopLayerIds.includes(item.id)}
          onChange={handleTopLayerChange}
        />
        <label className={styles.checkboxLabel} htmlFor={item.id}>
          {i18n.language === 'en' ? item.name_en : item.name}
        </label>
      </span>
    </Dropdown.MenuItem>
  ));

  return (
    <>
      <Dropdown dropup={true} pullRight={true}>
        <Dropdown.Toggle title={i18n._(t`Слои`)}>
          <LayersIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.root}>
          <MosaicLayerControl />
          <Dropdown.MenuHeader>{i18n._(t`Подложка`)}</Dropdown.MenuHeader>
          <Dropdown.MenuDivider />
          {baseLayerItems}
          {selectedBaseLayerId === 'Yandex' && topLayerItems}
        </Dropdown.Menu>
      </Dropdown>
      {mosaicLayerIds.includes(selectedMosaicLayerId) && <STACItemsLayer />}
    </>
  );
};

export default withI18n()(BaseLayerControl);

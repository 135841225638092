import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import { i18nMark } from '@lingui/react';

export const platformTypes: { [x: string]: string } = {
  AI: i18nMark('Аист-2Д'),
  ARCM: i18nMark('Арктика-М'),
  CBERS: i18nMark('CBERS'),
  KV: i18nMark('Канопус-В'),
  MM: i18nMark('Метеор-М'),
  MMBBP: i18nMark('Метеор-М (ББП)'),
  RDK: i18nMark('Ресурс-ДК'),
  RP: i18nMark('Ресурс-П'),
  EL: i18nMark('Электро-Л'),
  // MM2: i18nMark('Метеор-М2'), // (?)
  LS8: i18nMark('Landsat-8 (ББП)'),
  SNL2: i18nMark('Sentinel-2 (ББП)'),
  SZ2M: i18nMark('Зоркий-2М'),
  IRSR: i18nMark('ResourceSat'),
  GF: i18nMark('Gao Fen'),
  ZY: i18nMark('Zi Yuan'),
};

export const platforms: { [x: string]: string } = {
  // KV
  ARCM1: i18nMark('Арктика-М1'),
  CB04: i18nMark('CBERS-4'),
  ZY3: i18nMark('Zi Yuan'),
  IRSR2: i18nMark('ResourceSat-2'),
  IRSR2A: i18nMark('ResourceSat-2A'),
  EL2: i18nMark('Электро-Л2'),
  EL3: i18nMark('Электро-Л3'),
  AI2D: i18nMark('Аист-2Д'),
  KV1: i18nMark('Канопус-В1'),
  BKA1: i18nMark('БКА-1'),
  KV3: i18nMark('Канопус-В3'),
  KV4: i18nMark('Канопус-В4'),
  KV5: i18nMark('Канопус-В5'),
  KV6: i18nMark('Канопус-В6'),
  KVIK: i18nMark('Канопус-В-ИК'),
  SZ2M02: i18nMark('Зоркий-2М 02'),
  SZ2M04: i18nMark('Зоркий-2М 04'),
  SZ2M06: i18nMark('Зоркий-2М 06'),
  // RDK
  RDK1: i18nMark('Ресурс-ДК1'),
  // RP
  RP1: i18nMark('Ресурс-П1'),
  RP2: i18nMark('Ресурс-П2'),
  RP3: i18nMark('Ресурс-П3'),
  // MM
  MM1: i18nMark('Метеор-М1'),
  MM2: i18nMark('Метеор-М2'),
  // MM21: i18nMark('Метеор-М21'),
  MM22: i18nMark('Метеор-М22'),
  // LS8
  LS8: i18nMark('Landsat-8'),
  // SNL2
  SNL2A: i18nMark('Sentinel-2A'),
  SNL2B: i18nMark('Sentinel-2B'),
  GF6: i18nMark('Gao Fen-6'),
};

export const instruments: { [x: string]: string } = {
  AVR: i18nMark('Аврора'),
  BWD: i18nMark('BWD'),
  AWiFS: i18nMark('AWiFS'),
  GSA: i18nMark('ГСА'),
  GTN: i18nMark('Геотон'),
  GTNL1: i18nMark('СППИ "Сангур-1У"'),
  IRS: i18nMark('IRS'),
  LISS3: i18nMark('LISS-3'),
  LISS4: i18nMark('LISS-4'),
  MSI: i18nMark('MSI'),
  MSS: i18nMark('ПСС & МСС'),
  MSU101: i18nMark('КМСС (МСУ-100-1)'),
  MSU102: i18nMark('КМСС (МСУ-100-2)'),
  'MSU-GS-A': i18nMark('МСУ-ГС-А'),
  'MSU-GS-E': i18nMark('МСУ-ГС'),
  'MSU101,MSU102': i18nMark('КМСС'),
  'MSUTM101,MSUTM102': i18nMark('КМСС2 (МСУ-ТМ-100)'),
  MSUIKSRM: i18nMark('МСУ-ИК-СРМ'),
  MSUMR: i18nMark('МСУ-МР'),
  MSUTM101: i18nMark('КМСС2 (МСУ-ТМ-100-1)'),
  MSUTM102: i18nMark('КМСС2 (МСУ-ТМ-100-2)'),
  MUX: i18nMark('MUX'),
  MUXNAD: i18nMark('MUX'),
  'MUL12U-R': i18nMark('MUL12U-R'),
  OLITIRS: i18nMark('OLI-TIRS'),
  PANMUX: i18nMark('PANMUX'),
  PMS: i18nMark('PMS'),
  PSS: i18nMark('ПСС'),
  'PSS,MSS': i18nMark('ПСС & МСС'),
  SSR: i18nMark('КШМСА-СР'),
  SVR: i18nMark('КШМСА-ВР'),
  WFV: i18nMark('WFV'),
  WFI: i18nMark('WFI'),
};

// TODO - отправить MR на фикс кейса:
// https://lingui.js.org/tutorials/react-patterns.html#lazy-translations
// https://github.com/types/_definitelytypedmirror/packages/86450
// (PlatformTree.tsx - <Trans id={instrumentDescriptions[sensorData.instrumentIdentifier]} />)
export const instrumentDescriptions: { [x: string]: MessageDescriptor } = {
  // KV
  // PSS: i18nMark('Панхроматическая съёмочная система, разработанная для КА Канопус-В'),
  // MSS: i18nMark('Мультиспектральная съёмочная система, разработанная для КА Канопус-В'),
  AVR: t('instrument_description.AVRORA')`Широкозахватная мультиспектральная аппаратура`,
  MSUGS: t(
    'instrument_description.MSUGS'
  )`Геостационарная мультиспектральная гидрометеорологическая съёмочная система`,
  MSUGSA: t(
    'instrument_description.MSUGSA'
  )`Высокоэллиптическая мультиспектральная гидрометеорологическая съёмочная система`,
  MSS: t('instrument_description.PSS,MSS')`Панхроматическая и Многозональная съёмочные системы`, // bundle
  MSUIKSRM: t(
    'instrument_description.MSUIKSRM'
  )`Многоканальный радиометр среднего и дальнего инфракрасных диапазонов`,
  MSUMR: t('instrument_description.MSUMR')`Многозональное сканирующее устройство малого разрешения`,
  // RDK
  GTN: t('instrument_description.GTN')`Оптико-электронная аппаратура «Геотон»`,
  // RP
  GSA: t('instrument_description.GSA')`Гиперспектральная аппаратура`,
  SVR: t(
    'instrument_description.SVR'
  )`Широкозахватная мультиспектральная аппаратура высокого разрешения`,
  SSR: t(
    'instrument_description.SSR'
  )`Широкозахватная мультиспектральная аппаратура среднего разрешения`,
  GTNL1: t('instrument_description.GTNL1')`Оптико-электронная аппаратура «Геотон-Л1»`,
};

// NOTE - можно получать английские имена из API (есть поле в БД)
// или сделать mapping на основании min-max значений, с возможностью менять в том числе и русские названия
export const bandNames: { [x: string]: string } = {
  Панхром: i18nMark('Панхром'),
  Красный: i18nMark('Красный'),
  Зелёный: i18nMark('Зелёный'),
  Синий: i18nMark('Синий'),
  гиперспектр: i18nMark('Гиперспектр'),
  'каналы шириной 5-10 нм': i18nMark('каналы шириной 5-10 нм'),
  'Дальний ИК': i18nMark('Дальний ИК'),
  'Средний ИК': i18nMark('Средний ИК'),
  'Ближний ИК': i18nMark('Ближний ИК'),
};
